import React, { useState } from 'react';
import { useApp } from '../contexts/AppContext';
import { LEAGUES, getLeaguesByGroup } from '../config/leagues';
import { X, Plus, ChevronUp, ChevronDown, Eye, EyeOff, RotateCcw, Youtube, LogOut, Filter } from 'lucide-react';

const SPORTS_GROUPS = getLeaguesByGroup();

const getAllSportsGroups = () => {
  const groups = {};
  Object.values(LEAGUES).forEach(league => {
    if (league.sport === 'soccer') {
      // Handle soccer with all three categories
      const categoryName = league.category === 'international' 
        ? 'International Soccer' 
        : league.category === 'tournaments'
          ? 'Tournament Soccer'
          : 'Club Soccer';
      
      if (!groups[categoryName]) {
        groups[categoryName] = {
          leagues: [],
          emoji: '⚽',
          sport: 'soccer',
          category: league.category
        };
      }
      groups[categoryName].leagues.push(league.id);
    } else {
      // Handle other sports normally
      const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
      if (!groups[sport]) {
        groups[sport] = {
          leagues: [],
          emoji: league.emoji || '🏆',
          sport: league.sport
        };
      }
      groups[sport].leagues.push(league.id);
    }
  });
  return groups;
};

export default function PreferencesPage() {
  const { 
    defaultFilters, 
    updateDefaultFilters,
    spoilerMode, 
    toggleSpoilerMode,
    youtubeAuthorized,
    handleYouTubeAuth,
    youtubeProfile
  } = useApp();
  const [isFilterSectionVisible, setIsFilterSectionVisible] = useState(false);

  const dateFilters = [
    { id: 'recent', label: 'Recent', emoji: '🔙' },
    { id: 'upcoming', label: 'Upcoming', emoji: '🔜' }
  ];

  const toggleDateFilter = (dateId) => {
    updateDefaultFilters(prev => ({
      ...prev,
      date: prev.date.includes(dateId) ? prev.date : [dateId]
    }), { updateSession: true });
  };

  const removeDateFilter = (dateId) => {
    updateDefaultFilters(prev => ({
      ...prev,
      date: prev.date.filter(id => id !== dateId)
    }));
  };

  const toggleLeagueFilter = (leagueId) => {
    updateDefaultFilters(prev => ({
      ...prev,
      leagues: prev.leagues.includes(leagueId)
        ? prev.leagues.filter(id => id !== leagueId)
        : [...prev.leagues, leagueId]
    }));
  };

  const removeLeagueFilter = (leagueId) => {
    updateDefaultFilters(prev => ({
      ...prev,
      leagues: prev.leagues.filter(id => id !== leagueId)
    }));
  };

  const getMenuItems = () => {
    let groupedLeagues = {
      'My Teams': [{
        id: 'my-teams',
        name: 'My Teams',
        emoji: '⭐',
        displayPriority: -1
      }]
    };

    // Group leagues by sport
    Object.values(LEAGUES).forEach(league => {
      const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
      
      if (league.sport === 'soccer') {
        // Handle soccer categories
        const categoryName = league.category === 'international' 
          ? 'International'
          : league.category === 'tournaments'
            ? 'Tournaments'
            : 'Club';
          
        if (!groupedLeagues[categoryName]) {
          groupedLeagues[categoryName] = [];
        }
        groupedLeagues[categoryName].push(league);
      } else {
        // Handle other sports normally
        if (!groupedLeagues[sport]) {
          groupedLeagues[sport] = [];
        }
        groupedLeagues[sport].push(league);
      }
    });

    // Sort leagues within each group
    Object.keys(groupedLeagues).forEach(sport => {
      if (sport !== 'My Teams') {
        groupedLeagues[sport].sort((a, b) => (a.displayPriority || 999) - (b.displayPriority || 999));
      }
    });

    return groupedLeagues;
  };

  return (
    <div className="max-w-2xl mx-auto p-3 sm:p-4">
      <h1 className="text-2xl sm:text-3xl font-bold mb-8">Preferences</h1>
      
      <div className="space-y-8 sm:space-y-12">
        {/* Spoiler Mode Section */}
        <section>
          <h2 className="text-lg sm:text-xl font-semibold mb-2">Spoiler Mode</h2>
          <p className="text-sm text-gray-600 mb-4">
            Hide scores and game details to avoid spoilers when catching up on games.
          </p>
          <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="p-3 sm:p-4 bg-white">
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3">
                <div className="flex items-center gap-3">
                  {spoilerMode ? <EyeOff className="w-5 h-5 flex-shrink-0" /> : <Eye className="w-5 h-5 flex-shrink-0" />}
                  <p className="text-sm text-gray-500">Toggle to show / hide scores.</p>
                </div>
                <button
                  onClick={toggleSpoilerMode}
                  className={`
                    relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent 
                    transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2
                    ${spoilerMode ? 'bg-blue-600' : 'bg-gray-200'}
                  `}
                >
                  <span
                    className={`
                      pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 
                      transition duration-200 ease-in-out
                      ${spoilerMode ? 'translate-x-5' : 'translate-x-0'}
                    `}
                  />
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* YouTube Integration Section */}
        <section>
          <h2 className="text-lg sm:text-xl font-semibold mb-2">YouTube Integration</h2>
          <p className="text-sm text-gray-600 mb-4">
            Easily watch personalized highlights directly in the app by connecting your YouTube account.
          </p>
          <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="p-3 sm:p-4 bg-white">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <Youtube className="w-6 h-6 text-[#FF0000] flex-shrink-0" />
                  <div>
                    <p className="text-sm text-gray-600 break-normal">
                      {youtubeAuthorized 
                        ? 'Your YouTube account is connected'
                        : 'Connect your YouTube account to get started'
                      }
                    </p>
                  </div>
                </div>
                <button
                  onClick={async () => {
                    if (youtubeAuthorized) {
                      if (!window.confirm('Are you sure you want to disconnect your YouTube account?')) {
                        return;
                      }
                    }
                    try {
                      await handleYouTubeAuth();
                    } catch (error) {
                      console.error('Failed to handle YouTube auth:', error);
                    }
                  }}
                  className={`
                    w-full sm:w-auto px-4 py-2.5 sm:py-2 rounded-md text-sm font-medium inline-flex items-center justify-center gap-2
                    min-h-[48px] sm:min-h-[40px] transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2
                    ${youtubeAuthorized 
                      ? 'bg-white border border-gray-300 text-gray-700 hover:bg-red-50 hover:border-red-200 hover:text-red-600 focus:ring-red-500'
                      : 'bg-[#FF0000] text-white hover:bg-red-700 focus:ring-red-500'
                    }
                  `}
                >
                  {youtubeAuthorized ? (
                    <>
                      <LogOut className="w-4 h-4" />
                      Disconnect YouTube
                    </>
                  ) : (
                    <>
                      <Youtube className="w-4 h-4" />
                      Connect YouTube
                    </>
                  )}
                </button>
              </div>
              {youtubeAuthorized && youtubeProfile && (
                <div className="mt-4 pt-3 border-t">
                  <div className="flex flex-wrap items-center gap-2 sm:gap-3">
                    <span className="text-xs uppercase text-gray-500 font-medium">
                      Connected Account
                    </span>
                    <span className="text-sm font-medium px-2 py-0.5 rounded bg-red-50 text-red-700 border border-red-100">
                      {youtubeProfile.name}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Default Filters Section */}
        <section>
          <h2 className="text-lg sm:text-xl font-semibold mb-2">Default Filters</h2>
          <p className="text-sm text-gray-600 mb-4">
            Set your preferred filters for viewing games. These will be applied automatically but can be changed temporarily on the home page.
          </p>
          
          {/* Selected Filters Display */}
          <div className="flex flex-col sm:flex-row items-start gap-3 sm:gap-4">
            <div className="w-full sm:flex-grow">
              <div className="flex flex-wrap items-center gap-2">
                {/* Date Filters */}
                {defaultFilters.date.filter(dateId => dateId).map(dateId => (
                  <span key={dateId} className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-green-100 text-green-800">
                    <span>{dateFilters.find(f => f.id === dateId)?.emoji}</span>
                    {dateFilters.find(f => f.id === dateId)?.label}
                    <button
                      onClick={() => removeDateFilter(dateId)}
                      className="ml-1 hover:text-green-900"
                    >
                      <X size={14} />
                    </button>
                  </span>
                ))}

                {/* League Filters - Now with consolidated sport tags */}
                {Object.entries(getAllSportsGroups()).map(([sport, group]) => {
                  const allSelected = group.leagues.every(id => 
                    defaultFilters.leagues.includes(id)
                  );
                  
                  if (allSelected) {
                    return (
                      <span 
                        key={`all-${sport}`}
                        className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                      >
                        <span>{group.emoji}</span>
                        {sport.includes('Soccer') ? sport : `All ${sport}`}
                        <button
                          onClick={() => {
                            updateDefaultFilters(prev => ({
                              ...prev,
                              leagues: prev.leagues.filter(id => !group.leagues.includes(id))
                            }));
                          }}
                          className="ml-1 hover:text-blue-900"
                        >
                          <X size={14} />
                        </button>
                      </span>
                    );
                  }
                  return null;
                })}

                {/* Individual league tags - only show if sport not fully selected */}
                {defaultFilters.leagues.filter(leagueId => leagueId).map(leagueId => {
                  // Skip if part of a fully selected sport group
                  const isPartOfFullySelectedGroup = Object.values(getAllSportsGroups()).some(group => 
                    group.leagues.includes(leagueId) && 
                    group.leagues.every(id => defaultFilters.leagues.includes(id))
                  );
                  
                  if (isPartOfFullySelectedGroup) return null;

                  if (leagueId === 'my-teams') {
                    return (
                      <span key={leagueId} className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                        <span>⭐</span>
                        My Teams
                        <button
                          onClick={() => removeLeagueFilter(leagueId)}
                          className="ml-1 hover:text-blue-900"
                        >
                          <X size={14} />
                        </button>
                      </span>
                    );
                  }

                  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
                  if (!league) return null; // Skip if league not found

                  return (
                    <span key={leagueId} className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                      {league?.logo ? (
                        <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                      ) : (
                        <span>{league?.emoji}</span>
                      )}
                      {league?.shortName || league?.name}
                      <button
                        onClick={() => removeLeagueFilter(leagueId)}
                        className="ml-1 hover:text-blue-900"
                      >
                        <X size={14} />
                      </button>
                    </span>
                  );
                })}
              </div>
            </div>

            {/* Controls container - Remove the RotateCcw clear button */}
            <div className="flex items-center gap-2 w-full sm:w-auto justify-end">
              <button
                onClick={() => setIsFilterSectionVisible(prev => !prev)}
                className="inline-flex items-center justify-center w-12 h-12 sm:w-8 sm:h-8 rounded-md text-blue-600 hover:text-blue-800 hover:bg-blue-50"
              >
                <Filter size={20} />
              </button>
            </div>
          </div>

          {/* Filter Tag Bank */}
          {isFilterSectionVisible && (
            <div className="space-y-0.5 bg-gray-100 rounded-lg mt-2 border border-gray-200 shadow-sm">
              {/* Date Filters Section with distinct styling */}
              <div className="p-4 bg-white rounded-t-lg">
                {/* Add the Date Range header with Clear All button */}
                <div className="flex justify-between items-baseline mb-4">
                  <h3 className="text-xs font-medium text-gray-500">Date Range</h3>
                  <button
                    onClick={() => updateDefaultFilters({ date: ['recent'], leagues: [] })}
                    className="text-xs text-blue-600 hover:text-blue-800"
                  >
                    Clear All
                  </button>
                </div>

                <div className="flex flex-wrap gap-2">
                  {dateFilters.map(filter => {
                    const isSelected = defaultFilters.date.includes(filter.id);
                    
                    return (
                      <button
                        key={filter.id}
                        onClick={() => toggleDateFilter(filter.id)}
                        className={`
                          px-3 py-1 rounded-full text-sm inline-flex items-center gap-1
                          transition-colors duration-150
                          ${isSelected
                            ? 'bg-green-100 text-green-800 border border-green-200'
                            : 'bg-gray-50 text-gray-600 hover:bg-green-50 border border-gray-200'
                          }
                        `}
                      >
                        <span>{filter.emoji}</span>
                        {filter.label}
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* League Filters Section */}
              <div className="p-4 bg-white rounded-b-lg">
                {Object.entries(getMenuItems()).map(([sportName, leagues]) => (
                  <div key={sportName} className="mt-4 first:mt-0">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-xs font-medium text-gray-500">{sportName}</h3>
                      {leagues.length > 1 && (
                        <button
                          onClick={() => {
                            const leagueIds = leagues.map(league => league.id);
                            const allSelected = leagueIds.every(id => 
                              defaultFilters.leagues.includes(id)
                            );
                            
                            updateDefaultFilters(prev => ({
                              ...prev,
                              leagues: allSelected
                                ? prev.leagues.filter(id => !leagueIds.includes(id))
                                : [...new Set([...prev.leagues, ...leagueIds])]
                            }));
                          }}
                          className="text-xs text-blue-600 hover:text-blue-800"
                        >
                          {leagues.every(league => 
                            defaultFilters.leagues.includes(league.id)
                          ) ? 'Deselect All' : 'Select All'}
                        </button>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {leagues.map(league => (
                        <button
                          key={league.id}
                          onClick={() => toggleLeagueFilter(league.id)}
                          className={`
                            inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm
                            transition-colors duration-150
                            ${defaultFilters.leagues.includes(league.id)
                              ? 'bg-blue-100 text-blue-800 border border-blue-200'
                              : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                            }
                          `}
                        >
                          {league.logo ? (
                            <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                          ) : (
                            <span>{league.emoji}</span>
                          )}
                          {league.shortName || league.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
} 