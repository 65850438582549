import { db } from '../services/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const YOUTUBE_CLIENT_ID = '956682601077-5q1bdtrdjfpvbhi3i2le6opvku0ue5mg.apps.googleusercontent.com';

class YouTubeAuthService {
  constructor() {
    this.tokenKey = 'youtube_access_token';
    this.profileKey = 'youtube_profile';
    this.loadGoogleIdentity();
  }

  loadGoogleIdentity() {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  async signIn(userId) {
    try {
      return new Promise((resolve, reject) => {
        const client = google.accounts.oauth2.initTokenClient({
          client_id: YOUTUBE_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/youtube.readonly',
          callback: async (response) => {
            if (response.error) {
              reject(response);
              return;
            }

            try {
              // Save token to localStorage only (for security)
              localStorage.setItem(this.tokenKey, response.access_token);

              // Get user profile
              const userResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                  'Authorization': `Bearer ${response.access_token}`
                }
              });
              
              const profile = await userResponse.json();
              const userProfile = {
                name: profile.name,
                email: profile.email
              };
              
              // Save profile to both localStorage and Firestore
              localStorage.setItem(this.profileKey, JSON.stringify(userProfile));
              
              if (userId) {
                await updateDoc(doc(db, 'users', userId), {
                  youtubeProfile: userProfile,
                  youtubeAuthorized: true,
                  youtubeLastUpdated: new Date().toISOString()
                });
              }

              resolve({ token: response.access_token, profile: userProfile });
            } catch (error) {
              reject(error);
            }
          },
        });

        client.requestAccessToken();
      });
    } catch (error) {
      console.error('YouTube sign in error:', error);
      throw error;
    }
  }

  async signOut(userId) {
    try {
      localStorage.removeItem(this.tokenKey);
      localStorage.removeItem(this.profileKey);
      
      if (userId) {
        await updateDoc(doc(db, 'users', userId), {
          youtubeProfile: null,
          youtubeAuthorized: false,
          youtubeLastUpdated: new Date().toISOString()
        });
      }

      if (window.google?.accounts?.oauth2) {
        google.accounts.oauth2.revoke(this.getToken(), () => {
          console.log('Token revoked');
        });
      }
    } catch (error) {
      console.error('YouTube sign out error:', error);
      throw error;
    }
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  getProfile() {
    const profile = localStorage.getItem(this.profileKey);
    return profile ? JSON.parse(profile) : null;
  }

  isAuthorized() {
    return !!this.getToken();
  }
}

export const youtubeAuthService = new YouTubeAuthService(); 