import React, { useState, useEffect, useMemo } from 'react';
import { sportsApi } from '../services/sportsApi';
import { youtubeApi } from '../services/youtubeApi';
import { Play, X, Plus, ChevronUp, RotateCcw, Filter } from 'lucide-react';
import { Menu } from '@headlessui/react';
import QueueButton from './QueueButton';
import { useNavigate } from 'react-router-dom';
import SpoilerModeToggle from './SpoilerModeToggle';
import { LEAGUES, getLeaguesByGroup } from '../config/leagues';
import LoadingSpinner from './LoadingSpinner';
import { useApp } from '../contexts/AppContext';
import { youtubeAuthService } from '../services/youtubeAuthService';

const SPORTS_GROUPS = getLeaguesByGroup();

const DUMMY_GAMES = [
  {
    id: 'dummy-1',
    league: 'NBA',
    time: '7:30 PM',
    status: 'In Progress',
    statusDetail: '4th Qtr - 5:42',
    rawDate: new Date().toISOString(),
    homeTeam: {
      id: 'nba-1',
      name: 'Los Angeles Lakers',
      logo: 'https://a.espncdn.com/i/teamlogos/nba/500/lal.png',
      score: 112
    },
    awayTeam: {
      id: 'nba-2',
      name: 'Golden State Warriors',
      logo: 'https://a.espncdn.com/i/teamlogos/nba/500/gs.png',
      score: 108
    }
  },
  {
    id: 'dummy-2',
    league: 'Premier League',
    time: '3:00 PM',
    status: 'Final',
    statusDetail: 'Final',
    rawDate: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
    homeTeam: {
      id: 'soccer-eng.1-361',
      name: 'Arsenal',
      logo: 'https://a.espncdn.com/i/teamlogos/soccer/500/359.png',
      score: 2
    },
    awayTeam: {
      id: 'soccer-eng.1-382',
      name: 'Manchester United',
      logo: 'https://a.espncdn.com/i/teamlogos/soccer/500/360.png',
      score: 1
    },
    hasHighlights: true
  }
];

const getShortTeamName = (fullName, league) => {
  
  // Special handling for international teams
  if (league.includes('World Cup') || 
      league.includes('Euro') || 
      league.includes('Copa America') || 
      league.includes('Gold Cup') || 
      league.includes('Nations League')) {
    return fullName;
  }

  // Remove common suffixes
  let shortName = fullName
    .replace(' FC', '')
    .replace(' CF', '')
    .replace(' SC', '')
    .replace(' CD', '')
    .replace(' AFC', '')
    .replace(' & Hove Albion', '');

  // For soccer leagues, keep the full shortened name
  if (['Premier League', 'La Liga', 'Serie A', 'MLS', 'Champions League', 'Europa League', 'Bundesliga', 'English Championship'].includes(league)) {
    return shortName;
  }

  // But only for non-soccer teams
  const words = shortName.split(' ');
  if (words.length > 1) {
    return words[words.length - 1];
  }
  
  return shortName;
};

const formatRelativeDate = (rawDate) => {
  // Create dates using local timezone
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Parse the game date and set to midnight in local timezone
  const gameDate = new Date(rawDate);
  const gameDateMidnight = new Date(
    gameDate.getFullYear(),
    gameDate.getMonth(),
    gameDate.getDate()
  );

  // Compare timestamps for accurate date matching
  if (gameDateMidnight.getTime() === today.getTime()) {
    return 'Today';
  } else if (gameDateMidnight.getTime() === yesterday.getTime()) {
    return 'Yesterday';
  } else if (gameDateMidnight.getTime() === tomorrow.getTime()) {
    return 'Tomorrow';
  } else {
    // For other dates, use local date string
    return gameDate.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'long', 
      day: 'numeric'
    });
  }
};

// Move normalizeTeamId helper function here, before the GamesDisplay component
const normalizeTeamId = (id, league) => {
  if (!id) return id;
  
  // If the ID already has the correct format, return it
  if (id.includes('basketball-nba-') || id.startsWith('wnba-')) {
    return id;
  }

  // For NBA games, prefix with basketball-nba-
  if (league === 'NBA') {
    return `basketball-nba-${id}`;
  }
  
  // For WNBA games, prefix with wnba-
  if (league === 'WNBA') {
    return `wnba-${id}`;
  }

  return id;
};

// Add this helper function to check if all leagues in a sport are selected
const getAllSportsGroups = () => {
  const groups = {};
  Object.values(LEAGUES).forEach(league => {
    if (league.sport === 'soccer') {
      // Handle soccer with all three categories
      const categoryName = league.category === 'international' 
        ? 'International Soccer' 
        : league.category === 'tournaments'
          ? 'Tournament Soccer'
          : 'Club Soccer';
      
      if (!groups[categoryName]) {
        groups[categoryName] = {
          leagues: [],
          emoji: '⚽',
          sport: 'soccer',
          category: league.category
        };
      }
      groups[categoryName].leagues.push(league.id);
    } else {
      // Handle other sports normally
      const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
      if (!groups[sport]) {
        groups[sport] = {
          leagues: [],
          emoji: league.emoji || '🏆',
          sport: league.sport
        };
      }
      groups[sport].leagues.push(league.id);
    }
  });
  return groups;
};

// Add this helper to get sport emoji
const getSportEmoji = (sport) => {
  const emojis = {
    Basketball: '🏀',
    Football: '🏈',
    Baseball: '⚾',
    Hockey: '🏒',
    'Club Soccer': '⚽',
    'International Soccer': '⚽',
    default: '🏆'
  };
  return emojis[sport] || emojis.default;
};

// Add this component for inline video
const InlineVideo = ({ videoId, onClose }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
    <div className="relative w-full max-w-3xl mx-4">
      <button 
        onClick={onClose}
        className="absolute -top-10 right-0 text-white hover:text-gray-300"
      >
        <X size={24} />
      </button>
      <div className="relative pt-[56.25%]">
        <iframe
          className="absolute inset-0 w-full h-full rounded-lg"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  </div>
);

function GamesDisplay({ 
  following, 
  initialGames = null,
  showFilters = true,
  bypassInternalFilters = false
}) {
  // Add this with the other state declarations at the top of the component
  const [filterButtonOpacity, setFilterButtonOpacity] = useState(1);
  
  // Add scroll listener effect right after the state declarations
  useEffect(() => {
    let timeout;
    
    const handleScroll = () => {
      // Make button more transparent while scrolling
      setFilterButtonOpacity(0.4);
      
      // Clear any existing timeout
      clearTimeout(timeout);
      
      // Reset opacity after scrolling stops (after 150ms of no scrolling)
      timeout = setTimeout(() => {
        setFilterButtonOpacity(1);
      }, 150);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
    };
  }, []);

  // Add isTeamFollowed function definition here, right at the start
  const isTeamFollowed = (teamId, league) => {
    const normalizedId = normalizeTeamId(teamId, league);
    return following.includes(normalizedId);
  };

  const { 
    defaultFilters, 
    activeFilters, 
    setActiveFilters,
    updateDefaultFilters,
    updateSessionFilters,
    spoilerMode,
    isAuthenticated,
    sessionFilters,
    isLoading: contextLoading,
    channelSettings,
    youtubeAuthorized
  } = useApp();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedView, setSelectedView] = useState('my-teams');
  const [dateRange, setDateRange] = useState('recent');
  const [showHighlights, setShowHighlights] = useState(false);
  const [highlightVideoId, setHighlightVideoId] = useState(null);
  const [dateFilter, setDateFilter] = useState('all');
  const [loadingHighlights, setLoadingHighlights] = useState({});
  const [showSpoilerOverlay, setShowSpoilerOverlay] = useState(true);
  const [videoStarted, setVideoStarted] = useState(false);
  const [fullscreenTimeout, setFullscreenTimeout] = useState(null);
  const navigate = useNavigate();
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isFilterSectionVisible, setIsFilterSectionVisible] = useState(false);
  const [showDelayedSpinner, setShowDelayedSpinner] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [activeGameId, setActiveGameId] = useState(null);
  const [videoUrls, setVideoUrls] = useState({});

  // Add a ref to track initial mount
  const isFirstMount = React.useRef(true);

  // Add a guard for the initial render
  if (contextLoading || !activeFilters) {
    return (
      <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8 text-center">
        <LoadingSpinner />
      </div>
    );
  }

  // Update the filter initialization effect
  useEffect(() => {
    if (contextLoading) return; // Don't initialize if context is still loading
    
    setIsInitializing(true);
    
    try {
      const effectivelyAuthenticated = isAuthenticated || 
        (defaultFilters && Object.keys(defaultFilters).length > 0) ||
        (following && following.length > 0);

      const newFilters = {
        date: ['today', 'recent'],
        leagues: []
      };

      if (sessionFilters) {
        setActiveFilters(sessionFilters);
      } else if (effectivelyAuthenticated && defaultFilters?.leagues) {
        newFilters.leagues = [...defaultFilters.leagues];
        setActiveFilters(newFilters);
        setSessionFilters(newFilters);
      } else {
        newFilters.leagues = ['my-teams'];
        setActiveFilters(newFilters);
        setSessionFilters(newFilters);
      }
    } catch (err) {
      setActiveFilters({
        date: ['today', 'recent'],
        leagues: []
      });
    } finally {
      setIsInitializing(false);
    }
  }, [isAuthenticated, defaultFilters, following, sessionFilters, contextLoading]);

  // Keep the separate effect for handling filter changes
  useEffect(() => {
    if (!isInitializing && activeFilters) {
      if (activeFilters.date.length > 0 || activeFilters.leagues.length > 0) {
        loadGames();
      }
    }
  }, [activeFilters, isInitializing]);

  const handleFilterChange = (newFilters) => {
    updateSessionFilters(newFilters);
  };

  // Define filter options
  const dateFilters = [
    { id: 'recent', label: 'Recent', emoji: '🔙' },
    { id: 'upcoming', label: 'Upcoming', emoji: '🔜' }
  ];

  // First, update the HighlightsButton component to accept a URL prop
  const HighlightsButton = ({ onClick, loading, gameId, game, videoUrl }) => {
    if (loading) {
      return (
        <div className="text-xs text-blue-600 animate-pulse">
          Loading...
        </div>
      );
    }

    const buttonClasses = "inline-flex items-center gap-1 text-xs bg-blue-100 hover:bg-blue-200 text-blue-600 px-2 py-1 rounded transition-colors";

    const ButtonContent = () => (
      <>
        <Play size={12} />
        <span>Highlights</span>
      </>
    );

    // If we have a direct URL, render as button (we're handling the click in handleHighlightClick)
    return (
      <button
        onClick={onClick}
        className={buttonClasses}
      >
        <ButtonContent />
      </button>
    );
  };

  // Then update the handleHighlightClick function
  const handleHighlightClick = async (gameId, game) => {
    try {
      // If we already have the URL stored, use it directly
      if (!youtubeAuthorized && videoUrls[gameId]) {
        window.location.href = videoUrls[gameId];
        return;
      }

      setLoadingHighlights(prev => ({ ...prev, [gameId]: true }));
      
      const result = await youtubeApi.getHighlightVideo(
        game.homeTeam.name,
        game.awayTeam.name,
        game.rawDate,
        game.league,
        channelSettings
      );

      console.log('Highlight result:', result);

      if (!result || (!result.videoId && !result.searchQuery)) {
        throw new Error('No video found');
      }

      // For authorized users, show inline video
      if (youtubeAuthorized) {
        setHighlightVideoId(result.videoId);
        setActiveGameId(gameId);
        return;
      }

      // For unauthorized users, store the URL and open it immediately
      let url;
      if (result.type === 'direct' && result.videoId) {
        url = `https://www.youtube.com/watch?v=${result.videoId}`;
      } else if (result.searchQuery) {
        url = `https://www.youtube.com/results?search_query=${encodeURIComponent(result.searchQuery)}`;
      }

      if (url) {
        setVideoUrls(prev => ({ ...prev, [gameId]: url }));
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error fetching highlights:', error);
      alert('Unable to load highlights');
    } finally {
      setLoadingHighlights(prev => ({ ...prev, [gameId]: false }));
    }
  };

  const closeHighlight = () => {
    setHighlightVideoId(null);
    setActiveGameId(null);
  };

  // Add this effect to handle dummy games
  useEffect(() => {
    if (bypassInternalFilters) {
      setGames(DUMMY_GAMES);
      setLoading(false);
    } else if (initialGames) {
      setGames(initialGames);
      setLoading(false);
    } else {
      loadGames();
    }
  }, [initialGames, bypassInternalFilters]);

  const loadGames = async () => {
    if (bypassInternalFilters) {
      setGames(DUMMY_GAMES);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      let fetchedGames = [];
      
      if (activeFilters.date.length > 0 && activeFilters.leagues.length > 0) {
        const boundaries = activeFilters.date.reduce((acc, filter) => {
          const now = new Date();
          const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          
          switch (filter) {
            case 'recent':
              acc.push({
                start: new Date(todayStart.getTime() - (7 * 24 * 60 * 60 * 1000)), // 7 days ago
                end: todayEnd // Include today
              });
              break;
            case 'upcoming':
              acc.push({
                start: todayStart, // Start from beginning of today
                end: new Date(todayStart.getTime() + (7 * 24 * 60 * 60 * 1000)) // 7 days ahead from start of today
              });
              break;
          }
          return acc;
        }, []);

        // Only fetch if we have valid filters
        if (activeFilters.date.length > 0 && activeFilters.leagues.length > 0) {
          if (initialGames) {
            fetchedGames = initialGames;
          } else {
            const hasMyTeamsFilter = activeFilters.leagues.includes('my-teams');
            const leagueFilters = activeFilters.leagues.filter(id => id !== 'my-teams');
            let promises = [];

            // Calculate the maximum days needed for past and future based on selected filters
            const apiParams = {
              past: activeFilters.date.includes('recent') ? 7 : 0,
              future: activeFilters.date.includes('upcoming') ? 7 : 1 // Always include at least today
            };

            // Always include today if it's selected
            if (activeFilters.date.includes('today')) {
              apiParams.past = Math.max(apiParams.past, 1);
              apiParams.future = Math.max(apiParams.future, 1);
            }

            // Fetch games based on calculated parameters
            if (hasMyTeamsFilter && following.length > 0) {
              const teamPromises = following.map(teamId => {
                // Parse the team ID
                const parts = teamId.split('-');
                const sport = parts[0];
                
                return sportsApi.getTeamGames(teamId, sport, apiParams);
              });
              promises.push(...teamPromises);
            }

            if (leagueFilters.length > 0) {
              const leaguePromises = leagueFilters.map(leagueId => 
                sportsApi.getLeagueGames(leagueId, apiParams)
              );
              promises.push(...leaguePromises);
            }

            if (promises.length > 0) {
              const results = await Promise.all(promises);
              fetchedGames = results
                .flat()
                .filter((game, index, self) => 
                  index === self.findIndex(g => g.id === game.id)
                );
            }
          }

          // Filter games based on all selected date ranges
          fetchedGames = fetchedGames.filter(game => {
            const gameDate = new Date(game.rawDate);
            return boundaries.some(({ start, end }) => 
              gameDate >= start && gameDate <= end
            );
          });
        }
      }

      setGames(fetchedGames);
    } catch (err) {
      setError('Error loading games');
    } finally {
      setLoading(false);
    }
  };

  const filteredGames = useMemo(() => {
    if (!initialGames && !games) return [];
    
    if (bypassInternalFilters && initialGames) {
      return initialGames;
    }

    let filtered = initialGames || games;

    if (!filtered) return [];

    // Date filtering
    if (dateFilter !== 'all') {
      const now = new Date();
      filtered = filtered.filter(game => {
        if (!game?.date) return false;
        
        const gameDate = new Date(game.date);
        switch (dateFilter) {
          case '7days':
            return gameDate > now;
          case 'pastWeek':
            const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            return gameDate >= weekAgo && gameDate <= now;
          case 'pastMonth':
            const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            return gameDate >= monthAgo && gameDate <= now;
          default:
            return true;
        }
      });
    }

    return filtered;
  }, [initialGames, bypassInternalFilters, dateFilter, games]);

  // Modify the gamesByDate calculation to handle dummy games
  const gamesByDate = useMemo(() => {
    if (bypassInternalFilters) {
      return {
        [new Date().toISOString()]: DUMMY_GAMES
      };
    }

    // Sort all games first by timestamp
    const sortedGames = [...games].sort((a, b) => {
      const dateA = new Date(a.rawDate);
      const dateB = new Date(b.rawDate);
      
      // If dates are the same, sort by time
      if (dateA.toDateString() === dateB.toDateString()) {
        const timeA = a.time ? new Date(`1970/01/01 ${a.time}`).getTime() : 0;
        const timeB = b.time ? new Date(`1970/01/01 ${b.time}`).getTime() : 0;
        return timeA - timeB;
      }
      
      // For "upcoming" filter, sort ascending (oldest to newest)
      if (activeFilters.date.includes('upcoming')) {
        return dateA - dateB;
      }
      // For "recent" filter, sort descending (newest to oldest)
      return dateB - dateA;
    });

    // Then group the already-sorted games by date
    return sortedGames.reduce((acc, game) => {
      const gameDate = new Date(game.rawDate);
      const localDate = new Date(
        gameDate.getFullYear(),
        gameDate.getMonth(),
        gameDate.getDate()
      ).toISOString();

      if (!acc[localDate]) {
        acc[localDate] = [];
      }
      acc[localDate].push(game);
      return acc;
    }, {});
  }, [games, bypassInternalFilters, activeFilters.date]);

  // Add this effect to handle keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showHighlights && e.key.toLowerCase() === 'f') {
        const iframe = document.querySelector('.highlight-video-iframe');
        if (iframe) {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.webkitRequestFullscreen) { // Safari
            iframe.webkitRequestFullscreen();
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [showHighlights]);

  const renderGame = (game) => {
    const isActive = game.id === activeGameId;
    
    return (
      <div 
        key={game.id || game.gameId} 
        className={`px-4 pt-4 pb-6 border-b transition-all duration-300 ${
          isActive ? 'col-span-full row-span-2 !h-auto' : ''
        }`}
      >
        <div className={`${isActive ? 'pb-4' : ''}`}>
          <div className="absolute top-2 right-2 sm:right-3">
            <QueueButton 
              gameId={game.id} 
              data-bookmark-button={game.id}
              className="z-[40]"
            />
          </div>
          {/* ... other game display elements ... */}
        </div>

        {/* YouTube Player */}
        {isActive && highlightVideoId && (
          <div className="relative pt-4 border-t">
            <button
              onClick={closeHighlight}
              className="absolute top-4 right-4 z-10 p-1 rounded-full bg-white/80 hover:bg-white text-gray-600"
            >
              <X size={20} />
            </button>
            <div className="relative pt-[56.25%]">
              <iframe
                className="absolute inset-0 w-full h-full highlight-video-iframe"
                src={`https://www.youtube.com/embed/${highlightVideoId}?autoplay=1`}
                title="Game Highlights"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleTeamClick = (teamId) => {
    navigate(`/team/${teamId}`);
  };

  // Update the getMenuItems function
  const getMenuItems = () => {
    let groupedLeagues = {
      'My Teams': [{
        id: 'my-teams',
        name: 'My Teams',
        emoji: '⭐',
        displayPriority: -1
      }]
    };

    // Group leagues by sport
    Object.values(LEAGUES).forEach(league => {
      const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
      
      if (league.sport === 'soccer') {
        // Handle soccer categories
        const categoryName = league.category === 'international' 
          ? 'International'
          : league.category === 'tournaments'
            ? 'Tournaments'
            : 'Club';
          
        if (!groupedLeagues[categoryName]) {
          groupedLeagues[categoryName] = [];
        }
        groupedLeagues[categoryName].push(league);
      } else {
        // Handle other sports normally
        if (!groupedLeagues[sport]) {
          groupedLeagues[sport] = [];
        }
        groupedLeagues[sport].push(league);
      }
    });

    // Sort leagues within each group
    Object.keys(groupedLeagues).forEach(sport => {
      if (sport !== 'My Teams') {
        groupedLeagues[sport].sort((a, b) => (a.displayPriority || 999) - (b.displayPriority || 999));
      }
    });

    return groupedLeagues;
  };

  // Update the renderFilterSection function
  const renderFilterSection = () => {
    if (!activeFilters?.leagues) return null;
    
    return (
      <div className="sticky top-0 z-10">
        <div className="flex items-center justify-between px-4 border-b">
          <h2 className="text-sm font-medium text-gray-600">
            Today
          </h2>
          <button
            data-filter-button
            onClick={() => setIsFilterSectionVisible(prev => !prev)}
            className="inline-flex items-center justify-center w-10 h-10 text-blue-600 hover:text-blue-800 focus:outline-none"
          >
            {isFilterSectionVisible ? (
              <ChevronUp size={20} />
            ) : (
              <Filter size={20} />
            )}
          </button>
        </div>

        {/* Filter Tag Bank - Only visible when toggled */}
        {isFilterSectionVisible && (
          <div className="border-b bg-white mt-14">
            <div className="p-4 space-y-4">
              {/* Date Range header row with Clear All */}
              <div className="flex justify-between items-baseline">
                <h3 className="text-xs font-medium text-gray-500">Date Range</h3>
                <button
                  onClick={() => {
                    const defaultFilters = { 
                      date: ['today', 'recent'],
                      leagues: [] 
                    };
                    setActiveFilters(defaultFilters);
                    setSessionFilters(defaultFilters);
                  }}
                  className="text-xs text-blue-600 hover:text-blue-800"
                >
                  Clear All
                </button>
              </div>

              {/* Date Filter buttons */}
              <div className="flex flex-wrap gap-2">
                {dateFilters.map(filter => (
                  <button
                    key={filter.id}
                    onClick={() => toggleDateFilter(filter.id)}
                    className={`
                      px-3 py-1 rounded-full text-sm inline-flex items-center gap-1
                      transition-colors duration-150
                      ${activeFilters.date.includes(filter.id)
                        ? 'bg-green-100 text-green-800 border border-green-200'
                        : 'bg-gray-50 text-gray-600 hover:bg-green-50 border border-gray-200'
                      }
                    `}
                  >
                    <span>{filter.emoji}</span>
                    {filter.label}
                  </button>
                ))}
              </div>

              {/* League Filters */}
              {Object.entries(getMenuItems()).map(([sportName, leagues]) => (
                <div key={sportName}>
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xs font-medium text-gray-500">{sportName}</h3>
                    {leagues.length > 1 && (
                      <button
                        onClick={() => {
                          const leagueIds = leagues.map(league => league.id);
                          const allSelected = leagueIds.every(id => 
                            activeFilters.leagues.includes(id)
                          );
                          
                          setActiveFilters(prev => ({
                            ...prev,
                            leagues: allSelected
                              ? prev.leagues.filter(id => !leagueIds.includes(id))
                              : [...new Set([...prev.leagues, ...leagueIds])]
                          }));
                        }}
                        className="text-xs text-blue-600 hover:text-blue-800"
                      >
                        {leagues.every(league => 
                          activeFilters.leagues.includes(league.id)
                        ) ? 'Deselect All' : 'Select All'}
                      </button>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {leagues.map(league => (
                      <button
                        key={league.id}
                        onClick={() => toggleLeagueFilter(league.id)}
                        className={`
                          inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm
                          transition-colors duration-150
                          ${activeFilters.leagues.includes(league.id)
                            ? 'bg-blue-100 text-blue-800 border border-blue-200'
                            : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                          }
                        `}
                      >
                        {league.logo ? (
                          <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                        ) : (
                          <span>{league.emoji}</span>
                        )}
                        {league.shortName || league.name}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Update the filter helper functions
  const toggleLeagueFilter = (leagueId) => {
    updateSessionFilters(prev => ({
      ...prev,
      leagues: prev.leagues.includes(leagueId)
        ? prev.leagues.filter(id => id !== leagueId)
        : [...prev.leagues, leagueId]
    }));
  };

  const removeLeagueFilter = (leagueId) => {
    updateSessionFilters(prev => ({
      ...prev,
      leagues: prev.leagues.filter(id => id !== leagueId)
    }));
  };

  const toggleDateFilter = (dateId) => {
    updateSessionFilters(prev => ({
      ...prev,
      date: prev.date.includes(dateId) ? prev.date : [dateId]
    }));
  };

  const removeDateFilter = (dateId) => {
    updateSessionFilters(prev => ({
      ...prev,
      date: prev.date.filter(id => id !== dateId)
    }));
  };

  // Add this useEffect to handle the delayed spinner
  useEffect(() => {
    let timeout;
    if (loading && !initialGames) {
      // Only show spinner if loading takes longer than 500ms
      timeout = setTimeout(() => {
        setShowDelayedSpinner(true);
      }, 500);
    } else {
      setShowDelayedSpinner(false);
    }

    return () => clearTimeout(timeout);
  }, [loading, initialGames]);

  // At the start of the component
  useEffect(() => {
  }, [following, games, activeFilters, bypassInternalFilters]);

  // Add a guard at the start of the render to show dummy games
  if (bypassInternalFilters) {
    const dummyGamesByDate = {
      [new Date().toISOString()]: DUMMY_GAMES
    };

    return (
      <div className="mx-0 sm:mx-4 lg:mx-8">
        {Object.entries(dummyGamesByDate).map(([date, dateGames]) => (
          <div key={date} className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-4 bg-gray-50 border-b">
              <h2 className="font-medium text-gray-900">
                Today
              </h2>
            </div>
            <div className="divide-y">
              {dateGames.map(game => {
                const isFollowingHome = isTeamFollowed(game.homeTeam.id, game.league);
                const isFollowingAway = isTeamFollowed(game.awayTeam.id, game.league);
                
                return (
                  <div 
                    key={game.id} 
                    className={`px-2 pt-4 pb-8 sm:px-6 sm:pt-5 sm:pb-8 transition-colors relative
                      ${game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                        ? 'bg-gray-50/50' 
                        : ''
                      }`}
                  >
                    <div className="flex items-center justify-between text-xs sm:text-sm text-gray-500 mb-6">
                      <span>{game.league}</span>
                      <div className="flex items-center gap-4">
                        <span>
                          {game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                            ? '---'
                            : game.time
                          }
                        </span>
                        <QueueButton 
                          gameId={game.id} 
                          data-bookmark-button={game.id}
                          className="z-[40]"
                        />
                      </div>
                    </div>

                    {/* Teams and Score */}
                    <div className={`grid grid-cols-3 items-center gap-4 sm:grid-cols-[2fr_1fr_2fr]
                      ${(game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled'))
                        ? 'opacity-75'
                        : ''
                      }`}
                    >
                      {/* Home Team */}
                      <div className="flex flex-col items-center gap-1 text-center">
                        {game.homeTeam.logo && (
                          <img 
                            src={game.homeTeam.logo} 
                            alt=""
                            className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                            onClick={() => handleTeamClick(game.homeTeam.id)}
                          />
                        )}
                        <div className="flex flex-col items-center">
                          <span className={`text-xs font-medium ${following.includes(game.homeTeam.id) ? 'text-blue-600' : ''}`}>
                            {getShortTeamName(game.homeTeam.name, game.league)}
                          </span>
                          {game.homeTeam.record && (
                            <span className="text-[10px] text-gray-500">
                              {game.homeTeam.record}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Score and Highlights Section */}
                      <div className="text-center">
                        {game.status?.includes('STATUS_') ? (
                          <div>
                            <div className="font-bold text-sm">
                              {spoilerMode ? (
                                <span className="text-green-600">LIVE</span>
                              ) : (
                                `${game.homeTeam.score} - ${game.awayTeam.score}`
                              )}
                            </div>
                            <div className="text-xs text-green-600">
                              {spoilerMode ? 'In Progress' : game.statusDetail}
                            </div>
                          </div>
                        ) : game.status === 'Final' ? (
                          <div>
                            <div className="font-bold text-sm">
                              {spoilerMode ? (
                                'FINAL'
                              ) : (
                                `${game.homeTeam.score} - ${game.awayTeam.score}`
                              )}
                            </div>
                              {(game.hasHighlights || youtubeApi.isHighlightAvailable(game)) && (
                                <HighlightsButton 
                                  onClick={() => handleHighlightClick(game.id, game)}
                                  loading={loadingHighlights[game.id]}
                                  gameId={game.id}
                                  game={game}
                                  videoUrl={!youtubeAuthorized ? videoUrls[game.id] : null}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="text-gray-500 text-sm">vs</div>
                          )}
                      </div>

                      {/* Away Team */}
                      <div className="flex flex-col items-center gap-1 text-center">
                        {game.awayTeam.logo && (
                          <img 
                            src={game.awayTeam.logo} 
                            alt=""
                            className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                            onClick={() => handleTeamClick(game.awayTeam.id)}
                          />
                        )}
                        <div className="flex flex-col items-center">
                          <span className={`text-xs font-medium ${following.includes(game.awayTeam.id) ? 'text-blue-600' : ''}`}>
                            {getShortTeamName(game.awayTeam.name, game.league)}
                          </span>
                          {game.awayTeam.record && (
                            <span className="text-[10px] text-gray-500">
                              {game.awayTeam.record}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Add this component inside GamesDisplay
  const VideoModal = ({ videoId, onClose }) => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative w-full max-w-4xl mx-4">
        <button 
          onClick={onClose}
          className="absolute -top-10 right-0 text-white hover:text-gray-300"
        >
          <X size={24} />
        </button>
        <div className="relative pt-[56.25%] bg-black rounded-lg overflow-hidden">
          <iframe
            className="absolute inset-0 w-full h-full"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="Game Highlights"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="-mx-4 bg-gray-50 relative">
      {showFilters && (
        <div className="sticky top-0 z-10">
          {/* Update the filter button container positioning */}
          <div 
            className="fixed z-20"
            style={{ 
              top: '5rem',
              right: 'max(1rem, calc((100vw - 1280px) / 2 + 2rem))'
              // Increased max-width to 1280px and adjusted the offset to 2rem
            }}
          >
            <button
              data-filter-button
              onClick={() => setIsFilterSectionVisible(prev => !prev)}
              className="inline-flex items-center justify-center w-8 h-8 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-full shadow-sm transition-opacity duration-150"
              style={{ opacity: filterButtonOpacity }}
            >
              {isFilterSectionVisible ? (
                <ChevronUp size={16} />
              ) : (
                <Filter size={16} />
              )}
            </button>
          </div>

          {/* Filter section */}
          {isFilterSectionVisible && (
            <div className="border-b bg-white mt-12 z-10">
              <div className="p-4 space-y-4">
                {/* Date Range header row with Clear All */}
                <div className="flex justify-between items-baseline">
                  <h3 className="text-xs font-medium text-gray-500">Date Range</h3>
                  <button
                    onClick={() => {
                      const defaultFilters = { 
                        date: ['today', 'recent'],
                        leagues: [] 
                      };
                      setActiveFilters(defaultFilters);
                      setSessionFilters(defaultFilters);
                    }}
                    className="text-xs text-blue-600 hover:text-blue-800"
                  >
                    Clear All
                  </button>
                </div>

                {/* Date Filter buttons */}
                <div className="flex flex-wrap gap-2">
                  {dateFilters.map(filter => (
                    <button
                      key={filter.id}
                      onClick={() => toggleDateFilter(filter.id)}
                      className={`
                        px-3 py-1 rounded-full text-sm inline-flex items-center gap-1
                        transition-colors duration-150
                        ${activeFilters.date.includes(filter.id)
                          ? 'bg-green-100 text-green-800 border border-green-200'
                          : 'bg-gray-50 text-gray-600 hover:bg-green-50 border border-gray-200'
                        }
                      `}
                    >
                      <span>{filter.emoji}</span>
                      {filter.label}
                    </button>
                  ))}
                </div>

                {/* League Filters */}
                {Object.entries(getMenuItems()).map(([sportName, leagues]) => (
                  <div key={sportName}>
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-xs font-medium text-gray-500">{sportName}</h3>
                      {leagues.length > 1 && (
                        <button
                          onClick={() => {
                            const leagueIds = leagues.map(league => league.id);
                            const allSelected = leagueIds.every(id => 
                              activeFilters.leagues.includes(id)
                            );
                            
                            setActiveFilters(prev => ({
                              ...prev,
                              leagues: allSelected
                                ? prev.leagues.filter(id => !leagueIds.includes(id))
                                : [...new Set([...prev.leagues, ...leagueIds])]
                            }));
                          }}
                          className="text-xs text-blue-600 hover:text-blue-800"
                        >
                          {leagues.every(league => 
                            activeFilters.leagues.includes(league.id)
                          ) ? 'Deselect All' : 'Select All'}
                        </button>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {leagues.map(league => (
                        <button
                          key={league.id}
                          onClick={() => toggleLeagueFilter(league.id)}
                          className={`
                            inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm
                            transition-colors duration-150
                            ${activeFilters.leagues.includes(league.id)
                              ? 'bg-blue-100 text-blue-800 border border-blue-200'
                              : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                            }
                          `}
                        >
                          {league.logo ? (
                            <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                          ) : (
                            <span>{league.emoji}</span>
                          )}
                          {league.shortName || league.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {((showDelayedSpinner || isInitializing) || error || (!loading && !error && games.length === 0 && !bypassInternalFilters)) && (
        <div className="bg-gray-50">
          {(showDelayedSpinner || isInitializing) && (
            <div className="p-6 sm:p-8 text-center">
              <LoadingSpinner />
            </div>
          )}
          
          {error && (
            <div className="p-6 sm:p-8 text-center text-red-500">
              {error}
            </div>
          )}

          {!loading && !error && games.length === 0 && !bypassInternalFilters && (
            <div className="p-6 sm:p-8 text-center">
              {activeFilters.leagues.includes('my-teams') && following.length === 0 ? (
                <div className="space-y-2">
                  <p className="text-gray-500">You haven't followed any teams yet!</p>
                  <p className="text-sm text-gray-400">
                    Follow teams to see their games appear here
                  </p>
                </div>
              ) : (
                <div>
                  <LoadingSpinner />
                  <p className="text-gray-500 mt-4">
                    {activeFilters.leagues.length === 0 && !activeFilters.date.length
                      ? "Click the filter icon above to select the games you want to see"
                      : activeFilters.leagues.length > 0 && !activeFilters.date.length
                      ? "Click the + icon above to select leagues and dates"
                      : "Add filters using the + icon above to see games"}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Render games grouped by date */}
      {Object.entries(gamesByDate)
        .sort(([dateA], [dateB]) => {
          if (bypassInternalFilters) return 0;
          
          const dateAObj = new Date(dateA);
          const dateBObj = new Date(dateB);
          
          // For "upcoming" filter, sort ascending (oldest to newest)
          if (activeFilters.date.includes('upcoming')) {
            return dateAObj - dateBObj;
          }
          // For "recent" filter, sort descending (newest to oldest)
          return dateBObj - dateAObj;
        })
        .map(([date, dateGames], dateIndex, datesArrays) => {
          const showDateHeader = (dateIndex === 0 && !showFilters) || // Only show if it's first and filters are hidden
            (dateIndex > 0 && formatRelativeDate(date) !== formatRelativeDate(datesArrays[dateIndex - 1][0]));

          return (
            <div key={date} className={`bg-white overflow-hidden ${dateIndex === 0 ? 'mt-2' : 'mt-4'}`}>
              <div className="px-1 py-3 bg-gray-50 border-b">
                <h2 className="text-sm font-medium text-gray-600 px-2">
                  {formatRelativeDate(date)}
                </h2>
              </div>
              
              <div className="divide-y">
                {dateGames.map(game => {
                  const isFollowingHome = isTeamFollowed(game.homeTeam.id, game.league);
                  const isFollowingAway = isTeamFollowed(game.awayTeam.id, game.league);
                  
                  return (
                    <div 
                      key={game.id} 
                      className={`px-2 pt-4 pb-8 sm:px-6 sm:pt-5 sm:pb-8 transition-colors relative
                        ${game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                          ? 'bg-gray-50/50' 
                          : ''
                        }`}
                    >
                      <div className="flex items-center justify-between text-xs sm:text-sm text-gray-500 mb-6">
                        <span>{game.league}</span>
                        <div className="flex items-center gap-4">
                          <span>
                            {game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                              ? '---'
                              : game.time
                            }
                          </span>
                          <QueueButton 
                            gameId={game.id} 
                            data-bookmark-button={game.id}
                            className="z-[40]"
                          />
                        </div>
                      </div>

                      {/* Teams and Score */}
                      <div className={`grid grid-cols-3 items-center gap-4 sm:grid-cols-[2fr_1fr_2fr]
                        ${(game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled'))
                          ? 'opacity-75'
                          : ''
                        }`}
                      >
                        {/* Home Team */}
                        <div className="flex flex-col items-center gap-1 text-center">
                          {game.homeTeam.logo && (
                            <img 
                              src={game.homeTeam.logo} 
                              alt=""
                              className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                              onClick={() => handleTeamClick(game.homeTeam.id)}
                            />
                          )}
                          <div className="flex flex-col items-center">
                            <span className={`text-xs font-medium ${following.includes(game.homeTeam.id) ? 'text-blue-600' : ''}`}>
                              {getShortTeamName(game.homeTeam.name, game.league)}
                            </span>
                            {game.homeTeam.record && (
                              <span className="text-[10px] text-gray-500">
                                {game.homeTeam.record}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Score and Highlights Section */}
                        <div className="text-center">
                          {game.status?.includes('STATUS_') ? (
                            <div>
                              <div className="font-bold text-sm">
                                {spoilerMode ? (
                                  <span className="text-green-600">LIVE</span>
                                ) : (
                                  `${game.homeTeam.score} - ${game.awayTeam.score}`
                                )}
                              </div>
                              <div className="text-xs text-green-600">
                                {spoilerMode ? 'In Progress' : game.statusDetail}
                              </div>
                            </div>
                          ) : game.status === 'Final' ? (
                            <div>
                              <div className="font-bold text-sm">
                                {spoilerMode ? (
                                  'FINAL'
                                ) : (
                                  `${game.homeTeam.score} - ${game.awayTeam.score}`
                                )}
                              </div>
                              {(game.hasHighlights || youtubeApi.isHighlightAvailable(game)) && (
                                <HighlightsButton 
                                  onClick={() => handleHighlightClick(game.id, game)}
                                  loading={loadingHighlights[game.id]}
                                  gameId={game.id}
                                  game={game}
                                  videoUrl={!youtubeAuthorized ? videoUrls[game.id] : null}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="text-gray-500 text-sm">vs</div>
                          )}
                        </div>

                        {/* Away Team */}
                        <div className="flex flex-col items-center gap-1 text-center">
                          {game.awayTeam.logo && (
                            <img 
                              src={game.awayTeam.logo} 
                              alt=""
                              className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                              onClick={() => handleTeamClick(game.awayTeam.id)}
                            />
                          )}
                          <div className="flex flex-col items-center">
                            <span className={`text-xs font-medium ${following.includes(game.awayTeam.id) ? 'text-blue-600' : ''}`}>
                              {getShortTeamName(game.awayTeam.name, game.league)}
                            </span>
                            {game.awayTeam.record && (
                              <span className="text-[10px] text-gray-500">
                                {game.awayTeam.record}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

      {/* Add modal at the end */}
      {highlightVideoId && (
        <VideoModal 
          videoId={highlightVideoId} 
          onClose={() => {
            setHighlightVideoId(null);
            setActiveGameId(null);
          }} 
        />
      )}
    </div>
  );
}
export default GamesDisplay;
