import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ChevronDown, ChevronUp, Search, Plus, Check, X } from 'lucide-react';
import { useApp } from './contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { 
  LEAGUES, 
  getLeaguesByGroup, 
  API_BASE_URL,
  getRelatedTeams
} from './config/leagues';
import LoadingSpinner from './components/LoadingSpinner';
import { debounce } from 'lodash';

const SPORT_DISPLAY_ORDER = {
  'Basketball': 1,
  'Football': 2,
  'Baseball': 3,
  'Hockey': 4,
  'Soccer': 5
};

const getTeamIndicator = (team) => {
  // Soccer indicators
  if (team.sport === 'soccer') {
    if (team.league === "FIFA Women's") return '⚽ (W)';
    if (team.league === "FIFA Men's") return '⚽ (M)';
    if (team.league === "Women's Super League") return '(W)';
    return '';
  }

  // College sports indicators
  if (team.league?.toLowerCase().includes('college') || 
      team.league?.toLowerCase().includes('ncaa')) {
    const isWomens = team.league?.toLowerCase().includes("women's") || 
                    team.league?.toLowerCase().includes('w ');
    
    switch (team.sport?.toLowerCase()) {
      case 'basketball':
        return `🏀 ${isWomens ? '(W)' : '(M)'}`;
      case 'football':
        return '🏈';
      case 'baseball':
        return '⚾';
      case 'softball':
        return '⚥';
      case 'hockey':
        return '🏒';
      case 'volleyball':
        return '🏐';
      default:
        return isWomens ? '(W)' : '(M)';
    }
  }

  // Professional leagues - only show indicators for WNBA
  if (team.sport === 'basketball' && team.league === 'WNBA') {
    return '🏀 (W)';
  }

  return '';
};

function FollowingTeams({ following, teamsBySport, onFollowToggle }) {
  const [expanded, setExpanded] = useState(true);
  
  console.log('FollowingTeams props:', { following, teamsBySport });
  
  // Memoize allTeams to prevent unnecessary recalculations
  const allTeams = useMemo(() => 
    Object.values(teamsBySport)
      .flatMap(sportTeams => Object.values(sportTeams).flat())
      .filter((team, index, self) => 
        index === self.findIndex(t => t.id === team.id)
      ),
    [teamsBySport]
  );
  
  // Memoize followedTeams to prevent unnecessary recalculations
  const followedTeams = useMemo(() => {
    const allFollowedTeams = allTeams.filter(team => following.includes(team.id));
    const uniqueTeamsMap = new Map();
    
    allFollowedTeams.forEach(team => {
      const isWomensTeam = 
        team.league?.toLowerCase().includes("women's") ||
        team.league?.toLowerCase().includes('wnba') ||
        team.league === "FIFA Women's" ||
        team.league === "Women's Super League";
      
      const isCollege = 
        team.league?.toLowerCase().includes('college') || 
        team.league?.toLowerCase().includes('ncaa');
      
      const teamKey = isCollege 
        ? `${team.name}-${team.sport}-${isWomensTeam ? 'W' : 'M'}`
        : `${team.name}${isWomensTeam ? '-W' : '-M'}`;
      
      if (!uniqueTeamsMap.has(teamKey) || 
          (team.league === 'Premier League' || 
           team.league === 'NBA' || 
           team.league === 'WNBA' ||
           team.league === 'NFL' || 
           team.league === 'MLB' || 
           team.league === 'NHL' ||
           team.league === "Women's Super League")) {
        uniqueTeamsMap.set(teamKey, team);
      }
    });
    
    return Array.from(uniqueTeamsMap.values())
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [allTeams, following]);
  
  const isLoading = following.length > 0 && allTeams.length === 0;

  return (
    <div className="space-y-2">
      {isLoading ? (
        <div className="w-full text-center py-4">
          <LoadingSpinner />
        </div>
      ) : followedTeams.length === 0 ? (
        <div className="w-full text-center py-2 text-gray-500">
          No teams followed yet. Follow some teams below!
        </div>
      ) : (
        <div className="flex flex-wrap gap-2">
          {followedTeams.map(team => (
            <button
              key={`following-${team.id}-${team.league}`}
              className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
            >
              <img 
                src={team.logo} 
                alt={team.name} 
                className="w-4 h-4 object-contain"
              />
              <span>
                {team.name}
                <span className="text-xs ml-1 text-gray-500">{getTeamIndicator(team)}</span>
              </span>
              <X
                size={14}
                className="text-blue-600 hover:text-red-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onFollowToggle(team.id);
                }}
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

function SearchResults({ searchTerm, teamsBySport, following, onFollowToggle }) {
  // Get all teams and remove duplicates by name, keeping gender and college sports separate
  const allTeams = useMemo(() => {
    console.log('Rebuilding search results with:', { teamsBySport, following });
    const teams = Object.values(teamsBySport)
      .flatMap(sportTeams => Object.values(sportTeams).flat());
    
    // Create a Map to store unique teams
    const uniqueTeamsMap = new Map();
    
    teams.forEach(team => {
      const isWomensTeam = 
        team.league?.toLowerCase().includes("women's") ||
        team.league?.toLowerCase().includes('wnba') ||
        team.league === "FIFA Women's" ||
        team.league === "Women's Super League";
      
      const isCollege = 
        team.league?.toLowerCase().includes('college') || 
        team.league?.toLowerCase().includes('ncaa');
      
      const teamKey = isCollege 
        ? `${team.name}-${team.sport}-${isWomensTeam ? 'W' : 'M'}`
        : `${team.name}${isWomensTeam ? '-W' : '-M'}`;
      
      if (!uniqueTeamsMap.has(teamKey) || 
          (team.league === 'Premier League' || 
           team.league === 'NBA' || 
           team.league === 'WNBA' ||
           team.league === 'NFL' || 
           team.league === 'MLB' || 
           team.league === 'NHL' ||
           team.league === "Women's Super League")) {
        uniqueTeamsMap.set(teamKey, {
          ...team,
          relatedIds: teams
            .filter(t => {
              const tIsWomens = 
                t.league?.toLowerCase().includes("women's") ||
                t.league?.toLowerCase().includes('wnba') ||
                t.league === "FIFA Women's" ||
                t.league === "Women's Super League";
              
              const tIsCollege = 
                t.league?.toLowerCase().includes('college') || 
                t.league?.toLowerCase().includes('ncaa');
              
              if (tIsCollege) {
                return t.name === team.name && 
                       tIsWomens === isWomensTeam && 
                       t.sport === team.sport;
              }
              
              return t.name === team.name && tIsWomens === isWomensTeam;
            })
            .map(t => t.id)
        });
      }
    });
    
    return Array.from(uniqueTeamsMap.values());
  }, [teamsBySport]);

  const filteredTeams = searchTerm
    ? allTeams
        .filter(team =>
          team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          team.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];

  // Debug logging for follow state
  useEffect(() => {
    console.log('Search Results following state:', following);
  }, [following]);

  const handleTeamToggle = useCallback((team) => {
    console.log('Toggling team:', team);
    console.log('Related IDs:', team.relatedIds);
    console.log('Current following state:', following);
    
    // Check if any of the related teams are currently followed
    const isAnyFollowed = team.relatedIds.some(id => following.includes(id));
    console.log('Is any related team followed:', isAnyFollowed);
    
    // Toggle all related teams
    onFollowToggle(team.id);
  }, [following, onFollowToggle]);

  if (!searchTerm) return null;

  return (
    <div className="space-y-2">
      {filteredTeams.length > 0 ? (
        <div className="flex flex-wrap gap-2">
          {filteredTeams.map(team => (
            <TeamButton
              key={`search-${team.id}`}
              team={team}
              isFollowing={team.relatedIds.some(id => following.includes(id))}
              onToggle={() => handleTeamToggle(team)}
            />
          ))}
        </div>
      ) : (
        <div className="text-gray-500 text-center py-2">
          No teams found matching "{searchTerm}"
        </div>
      )}
    </div>
  );
}

function TeamButton({ team, isFollowing, onToggle }) {
  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Team button clicked:', { team, isFollowing });
    onToggle();
  }, [team, isFollowing, onToggle]);

  return (
    <button
      onClick={handleClick}
      className={`
        inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm
        ${isFollowing
          ? 'bg-blue-100 text-blue-800'
          : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
        }
        transition-colors
      `}
    >
      <img 
        src={team.logo} 
        alt={team.name} 
        className="w-4 h-4 object-contain"
      />
      <span>
        {team.name}
        <span className="text-xs ml-1 text-gray-500">{getTeamIndicator(team)}</span>
      </span>
      {isFollowing ? (
        <Check size={14} className="text-blue-600" />
      ) : (
        <Plus size={14} className="text-gray-400" />
      )}
    </button>
  );
}

function FollowingPage() {
  const { following = [], toggleFollow } = useApp();
  const [expandedSports, setExpandedSports] = useState({});
  const [expandedLeagues, setExpandedLeagues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [teamsBySport, setTeamsBySport] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [isFilterSectionVisible, setIsFilterSectionVisible] = useState(false);
  const [isFollowingExpanded, setIsFollowingExpanded] = useState(false);

  const SPORTS_GROUPS = useMemo(() => getLeaguesByGroup(), []);

  useEffect(() => {
    console.log('🔍 FollowingPage Context:', {
      following,
      hasToggleFollow: !!toggleFollow,
      followingType: Array.isArray(following) ? 'array' : typeof following
    });
  }, [following, toggleFollow]);

  useEffect(() => {
    console.log('Available Sports Groups:', SPORTS_GROUPS);
    console.log('Soccer Categories:', SPORTS_GROUPS.SOCCER?.subCategories);
  }, [SPORTS_GROUPS]);

  useEffect(() => {
    console.log('🔍 Sports Groups:', SPORTS_GROUPS);
    console.log('🔍 Soccer Clubs:', SPORTS_GROUPS.Soccer?.subCategories?.CLUBS?.leagues);
    console.log('🔍 Soccer International:', SPORTS_GROUPS.Soccer?.subCategories?.INTERNATIONAL?.leagues);
  }, [SPORTS_GROUPS]);

  useEffect(() => {
    const loadTeams = debounce(async () => {
      // Load teams data for sports that have followed teams
      const loadFollowedTeamsData = async () => {
        console.log('Current following list:', following);

        // Extract sport from team ID and convert to proper case
        const followedSports = new Set(
          following.map(fullTeamId => {
            // Special handling for WNBA teams - map them to Basketball
            if (fullTeamId.startsWith('wnba-')) {
              return 'Basketball';
            }
            
            // Handle soccer teams with their complex IDs
            if (fullTeamId.startsWith('soccer-')) {
              return 'Soccer';
            }
            
            const [sport] = fullTeamId.split('-');
            return sport.charAt(0).toUpperCase() + sport.slice(1).toLowerCase();
          })
        );

        console.log('Found followed sports:', [...followedSports]);
        console.log('Available sports:', Object.keys(SPORTS_GROUPS));

        // Load data for all followed sports simultaneously
        const loadPromises = Array.from(followedSports).map(sport => {
          const sportGroup = SPORTS_GROUPS[sport];
          if (!sportGroup) {
            console.warn(`Sport group not found for "${sport}". Available sports:`, Object.keys(SPORTS_GROUPS));
            return Promise.resolve();
          }

          // For Soccer, we need to load all subcategories
          if (sport === 'Soccer' && sportGroup.subCategories) {
            return Promise.all(
              Object.entries(sportGroup.subCategories).map(([categoryKey, category]) =>
                Promise.all(
                  Object.entries(category.leagues).map(([leagueName, leagueConfig]) =>
                    loadTeamsForLeague(sport, categoryKey, leagueName, leagueConfig)
                  )
                )
              )
            );
          }
          
          // For other sports, load normally
          return loadTeamsForSport(sport);
        });

        try {
          await Promise.all(loadPromises);
        } catch (error) {
          console.error('Error loading followed teams:', error);
        }
      };

      if (following.length > 0) {
        loadFollowedTeamsData();
      }
    }, 500);

    loadTeams();
    return () => loadTeams.cancel();
  }, [following]);

  // Add helper function to load teams for a specific soccer league
  const loadTeamsForLeague = async (sport, categoryKey, leagueName, leagueConfig) => {
    try {
      const endpoint = `${API_BASE_URL}/soccer/${leagueConfig.league}/teams`;
      console.log(`Fetching soccer teams for ${categoryKey} - ${leagueName} from:`, endpoint);
      
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      const teams = data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
        id: `soccer-${leagueConfig.league}-${team.team.id}`,
        espnId: team.team.id,
        name: team.team.displayName,
        abbreviation: team.team.abbreviation,
        logo: team.team.logos?.[0]?.href,
        league: leagueName,
        sport: 'soccer',
        category: categoryKey,
        emoji: leagueConfig.emoji,
        color: team.team.color || '000000',
        alternateColor: team.team.alternateColor
      })) || [];

      // Update teamsBySport with the new teams
      setTeamsBySport(prev => ({
        ...prev,
        Soccer: {
          ...prev.Soccer,
          [leagueName]: teams
        }
      }));

      return teams;
    } catch (error) {
      console.error(`Error loading teams for ${leagueName}:`, error);
      return [];
    }
  };

  const loadTeamsForSport = async (sportKey) => {
    if (teamsBySport[sportKey]) return Promise.resolve();
    
    setLoading(prev => ({ ...prev, [sportKey]: true }));
    try {
      const sportGroup = SPORTS_GROUPS[sportKey];
      
      // Special handling for Soccer with subcategories
      if (sportKey === 'Soccer' && sportGroup.subCategories) {
        const allSoccerTeams = {};
        
        // Load teams for each subcategory
        for (const [categoryKey, category] of Object.entries(sportGroup.subCategories)) {
          for (const [leagueName, leagueConfig] of Object.entries(category.leagues)) {
            try {
              const endpoint = `${API_BASE_URL}/soccer/${leagueConfig.league}/teams`;
              console.log(`Fetching soccer teams for ${categoryKey} - ${leagueName} from:`, endpoint);
              
              const response = await fetch(endpoint);
              if (!response.ok) continue;
              
              const data = await response.json();
              const teams = data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
                id: sportKey === 'soccer' ? 
                  `soccer-${leagueConfig.league}-${team.team.id}` : 
                  `${sportGroup.name.toLowerCase()}-${league}-${team.team.id}`,
                espnId: team.team.id,
                name: isCollege ? `${team.team.location} ${team.team.name}` : team.team.displayName,
                abbreviation: team.team.abbreviation,
                logo: team.team.logos?.[0]?.href,
                league: leagueName,
                sport: sportKey === 'soccer' ? 'soccer' : sportGroup.name.toLowerCase(),
                category: categoryKey,
                emoji: leagueConfig.emoji,
                color: team.team.color || '000000',
                alternateColor: team.team.alternateColor
              })) || [];
              
              if (teams.length > 0) {
                allSoccerTeams[leagueName] = teams;
              }
            } catch (error) {
              console.error(`Error loading soccer teams for ${leagueName}:`, error);
            }
          }
        }
        
        setTeamsBySport(prev => ({
          ...prev,
          [sportKey]: allSoccerTeams
        }));
        
      } else {
        // Handle other sports (including WNBA) as before
        const leaguePromises = Object.entries(sportGroup.leagues || {}).map(async ([leagueName, { league }]) => {
          try {
            const isCollege = league.includes('college');
            const isWNBA = leagueName === 'WNBA';
            
            const sportPath = isWNBA ? 'basketball/wnba' : `${sportGroup.name.toLowerCase()}/${league}`;
            const endpoint = `${API_BASE_URL}/${sportPath}/teams${isCollege ? '?limit=500&groups=50' : ''}`;
            
            const response = await fetch(endpoint);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            const teams = data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
              id: isWNBA ? `wnba-${team.team.id}` : `${sportGroup.name.toLowerCase()}-${league}-${team.team.id}`,
              espnId: team.team.id,
              name: isCollege ? `${team.team.location} ${team.team.name}` : team.team.displayName,
              abbreviation: team.team.abbreviation,
              logo: team.team.logos?.[0]?.href,
              league: leagueName,
              sport: isWNBA ? 'wnba' : sportGroup.name.toLowerCase(),
              emoji: sportGroup.leagues[leagueName].emoji,
              color: team.team.color || '000000',
              alternateColor: team.team.alternateColor,
              isWNBA: isWNBA,
              ...(isCollege && { conference: team.team.conferenceId })
            })) || [];
            
            return { leagueName, teams };
          } catch (error) {
            console.error(`Error loading teams for ${leagueName}:`, error);
            return { leagueName, teams: [] };
          }
        });

        const leagueResults = await Promise.all(leaguePromises);
        const sportTeams = leagueResults.reduce((acc, { leagueName, teams }) => {
          if (teams.length > 0) {
            acc[leagueName] = teams;
          }
          return acc;
        }, {});

        setTeamsBySport(prev => ({
          ...prev,
          [sportKey]: sportTeams
        }));
      }
      
    } catch (err) {
      console.error(`Error loading teams for ${sportKey}:`, err);
      setError(prev => ({
        ...prev,
        [sportKey]: 'Failed to load teams'
      }));
      return Promise.reject(err);
    } finally {
      setLoading(prev => ({ ...prev, [sportKey]: false }));
    }
  };

  const toggleSport = async (sportKey) => {
    const isExpanding = !expandedSports[sportKey];
    setExpandedSports(prev => ({
      ...prev,
      [sportKey]: isExpanding
    }));
    
    if (isExpanding) {
      await loadTeamsForSport(sportKey);
    }
  };

  const handleFollowToggle = useCallback(async (teamId) => {
    console.log('Follow toggle called with teamId:', teamId);
    
    if (!toggleFollow) {
      console.warn('toggleFollow function not available');
      return;
    }

    try {
      console.log('Current following state:', following);
      const targetState = !following.includes(teamId);
      console.log('Target state:', targetState);
      
      await toggleFollow(teamId, targetState);
      console.log('Follow toggle completed');
      
    } catch (error) {
      console.error('Error toggling team follow state:', error);
    }
  }, [following, toggleFollow]);

  const toggleSubCategory = (sportKey, categoryKey) => {
    setExpandedSubCategories(prev => ({
      ...prev,
      [`${sportKey}-${categoryKey}`]: !prev[`${sportKey}-${categoryKey}`]
    }));
  };

  // Add this new effect to handle search-based expansions
  useEffect(() => {
    if (!searchTerm) {
      // Reset expansions when search is cleared
      setExpandedSports({});
      setExpandedLeagues({});
      setExpandedSubCategories({});
      return;
    }

    // Load and expand all sports that haven't been loaded yet
    Object.keys(SPORTS_GROUPS).forEach(async (sportKey) => {
      if (!teamsBySport[sportKey]) {
        await loadTeamsForSport(sportKey);
      }
      
      // Expand the sport if it has matching teams
      const sportTeams = teamsBySport[sportKey] || {};
      const hasMatchingTeams = Object.values(sportTeams).some(teams =>
        teams.some(team =>
          team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          team.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      if (hasMatchingTeams) {
        setExpandedSports(prev => ({ ...prev, [sportKey]: true }));
        
        // For soccer, expand relevant subcategories
        if (sportKey === 'Soccer') {
          Object.keys(SPORTS_GROUPS.Soccer.subCategories).forEach(categoryKey => {
            setExpandedSubCategories(prev => ({
              ...prev,
              [`${sportKey}-${categoryKey}`]: true
            }));
          });
        }
      }
    });
  }, [searchTerm]);

  // Calculate unique followed teams count
  const followedTeamsCount = useMemo(() => {
    if (!following || !teamsBySport) return 0;

    // Get all teams
    const allTeams = Object.values(teamsBySport)
      .flatMap(sportTeams => Object.values(sportTeams).flat());
    
    // Create a Set to store unique team names (accounting for gender/college)
    const uniqueTeams = new Set();
    
    following.forEach(followedId => {
      const team = allTeams.find(t => t.id === followedId);
      if (team) {
        const isWomensTeam = 
          team.league?.toLowerCase().includes("women's") ||
          team.league?.toLowerCase().includes('wnba') ||
          team.league === "FIFA Women's" ||
          team.league === "Women's Super League";
        
        const isCollege = 
          team.league?.toLowerCase().includes('college') || 
          team.league?.toLowerCase().includes('ncaa');
        
        // Create a unique key for the team
        const teamKey = isCollege 
          ? `${team.name}-${team.sport}-${isWomensTeam ? 'W' : 'M'}`
          : `${team.name}${isWomensTeam ? '-W' : '-M'}`;
        
        uniqueTeams.add(teamKey);
      }
    });
    
    return uniqueTeams.size;
  }, [following, teamsBySport]);

  // Render the sports groups and their leagues
  return (
    <div className="max-w-4xl mx-auto py-4 px-2">
      <div className="bg-white rounded-lg shadow-sm">
        {/* Search Section - Moved to Top */}
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="relative w-full">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search teams..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 w-full border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none bg-white"
            />
          </div>
          {searchTerm && (
            <div className="mt-4">
              <SearchResults 
                searchTerm={searchTerm}
                teamsBySport={teamsBySport}
                following={following}
                onFollowToggle={handleFollowToggle}
              />
            </div>
          )}
        </div>

        {/* Collapsible Following Section */}
        <div className="border-b border-gray-200">
          <button
            onClick={() => setIsFollowingExpanded(!isFollowingExpanded)}
            className="w-full px-6 py-3 flex items-center justify-between hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-center gap-2">
              <span className="text-gray-700 font-medium">Currently Following</span>
              <span className="bg-blue-100 text-blue-700 text-sm px-2 py-0.5 rounded-full">
                {followedTeamsCount}
              </span>
            </div>
            {isFollowingExpanded ? (
              <ChevronUp size={18} className="text-gray-400" />
            ) : (
              <ChevronDown size={18} className="text-gray-400" />
            )}
          </button>

          {/* Expandable Content */}
          {isFollowingExpanded && (
            <div className="px-6 py-4 bg-gray-50">
              <FollowingTeams
                following={following}
                teamsBySport={teamsBySport}
                onFollowToggle={handleFollowToggle}
              />
            </div>
          )}
        </div>

        {/* Sports Categories Section */}
        <div className="px-6 py-4">
          <div className="divide-y divide-gray-100">
            {Object.entries(SPORTS_GROUPS)
              .sort(([sportKeyA], [sportKeyB]) => {
                return (SPORT_DISPLAY_ORDER[sportKeyA] || 999) - (SPORT_DISPLAY_ORDER[sportKeyB] || 999);
              })
              .map(([sportKey, sportGroup]) => (
                <div key={sportKey} className="py-3 first:pt-0 last:pb-0">
                  <button
                    onClick={() => toggleSport(sportKey)}
                    className="w-full px-4 py-2.5 hover:bg-gray-50 flex items-center justify-between rounded-md group"
                  >
                    <div className="flex items-center gap-3">
                      <span className="text-xl">{sportGroup.emoji}</span>
                      <span className="font-semibold text-gray-900">{sportGroup.name}</span>
                    </div>
                    <ChevronDown
                      size={18}
                      className={`text-gray-400 transition-transform duration-200 ${
                        expandedSports[sportKey] ? 'rotate-180' : ''
                      }`}
                    />
                  </button>

                  {expandedSports[sportKey] && (
                    <div className="mt-1 space-y-1">
                      {sportKey === 'Soccer' ? (
                        // Soccer subcategories
                        Object.entries(sportGroup.subCategories).map(([categoryKey, category]) => (
                          <div key={categoryKey} className="ml-4">
                            <button
                              onClick={() => toggleSubCategory(sportKey, categoryKey)}
                              className="w-full px-4 py-2 hover:bg-gray-50 flex items-center justify-between rounded-md group"
                            >
                              <span className="font-medium text-gray-700">{category.name}</span>
                              <ChevronDown
                                size={16}
                                className={`text-gray-400 transition-transform duration-200 ${
                                  expandedSubCategories[`${sportKey}-${categoryKey}`] ? 'rotate-180' : ''
                                }`}
                              />
                            </button>

                            {expandedSubCategories[`${sportKey}-${categoryKey}`] && (
                              <div className="mt-1 space-y-0.5">
                                {Object.entries(category.leagues).map(([leagueName, leagueConfig]) => (
                                  <LeagueTeams
                                    key={leagueName}
                                    leagueName={leagueName}
                                    teams={teamsBySport[sportKey]?.[leagueName] || []}
                                    following={following}
                                    onFollowToggle={handleFollowToggle}
                                    searchTerm={searchTerm}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        // Regular sport leagues
                        <div className="space-y-0.5">
                          {Object.entries(sportGroup.leagues || {}).map(([leagueName, leagueConfig]) => (
                            <LeagueTeams
                              key={leagueName}
                              leagueName={leagueName}
                              teams={teamsBySport[sportKey]?.[leagueName] || []}
                              following={following}
                              onFollowToggle={handleFollowToggle}
                              searchTerm={searchTerm}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// Add back the LeagueTeams component
function LeagueTeams({ leagueName, teams, following, onFollowToggle, searchTerm }) {
  const [expanded, setExpanded] = useState(false);
  const league = Object.values(LEAGUES).find(l => l.name === leagueName);
  
  const filteredTeams = teams
    .filter(team =>
      !searchTerm || 
      team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      team.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (searchTerm && filteredTeams.length > 0) {
      setExpanded(true);
    } else if (!searchTerm) {
      setExpanded(false);
    }
  }, [searchTerm, filteredTeams.length]);

  if (searchTerm && filteredTeams.length === 0) return null;

  return (
    <div className="ml-4">
      <button
        onClick={() => setExpanded(!expanded)}
        className="w-full px-4 py-2 hover:bg-gray-50 flex items-center justify-between rounded-md group"
      >
        <div className="flex items-center gap-2">
          {league?.logo ? (
            <img src={league.logo} alt="" className="w-5 h-5 object-contain" />
          ) : (
            <span className="text-lg">{league?.emoji}</span>
          )}
          <span className="text-gray-700">{leagueName}</span>
          {teams.filter(team => following.includes(team.id)).length > 0 && (
            <span className="text-sm bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
              {teams.filter(team => following.includes(team.id)).length}
            </span>
          )}
        </div>
        {expanded ? (
          <ChevronUp size={16} className="text-gray-400" />
        ) : (
          <ChevronDown size={16} className="text-gray-400" />
        )}
      </button>

      {expanded && (
        <div className="p-4">
          <div className="flex flex-wrap gap-2">
            {filteredTeams.map(team => (
              <TeamButton
                key={`league-${team.id}-${leagueName}`}
                team={team}
                isFollowing={following.includes(team.id)}
                onToggle={() => onFollowToggle(team.id)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Separate component for team row
function TeamRow({ team, following, onFollowToggle }) {
  const navigate = useNavigate();
  const isGolfPlayer = team.id.startsWith('golf-');
  const isFollowing = following.includes(team.id);

  return (
    <div className="flex items-center justify-between p-4 hover:bg-gray-50">
      <div className="flex items-center space-x-3">
        {team.logo ? (
          <img 
            src={team.logo} 
            alt={team.name} 
            className="w-8 h-8 object-contain cursor-pointer hover:opacity-80 transition-opacity"
            onClick={() => navigate(`/team/${team.id}`)}
          />
        ) : (
          <span className="text-2xl">{team.emoji}</span>
        )}
        <div>
          <div 
            className="font-medium cursor-pointer hover:text-blue-600"
            onClick={() => navigate(`/team/${team.id}`)}
          >
            {team.name}
            {isGolfPlayer && team.rank && (
              <span className="ml-2 text-sm text-gray-500">#{team.rank}</span>
            )}
          </div>
          {team.abbreviation && (
            <div className="text-sm text-gray-500">
              {isGolfPlayer ? team.nationality : team.abbreviation}
            </div>
          )}
          {isGolfPlayer && team.statistics && (
            <div className="text-xs text-gray-400">
              Wins: {team.statistics.wins} | Top 10s: {team.statistics.topTens}
            </div>
          )}
        </div>
      </div>
      <button
        onClick={() => onFollowToggle(team.id)}
        className={`p-2 rounded-full ${
          isFollowing
            ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
        aria-label={isFollowing ? 'Unfollow' : 'Follow'}
      >
        {isFollowing ? (
          <Check className="w-4 h-4" />
        ) : (
          <Plus className="w-4 h-4" />
        )}
      </button>
    </div>
  );
}

export default FollowingPage;