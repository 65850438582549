export const getShortTeamName = (fullName, league) => {
  // Special handling for international teams
  if (league.includes('World Cup') || 
      league.includes('Euro') || 
      league.includes('Copa America') || 
      league.includes('Gold Cup') || 
      league.includes('Nations League')) {
    return fullName;
  }

  // Remove common suffixes
  let shortName = fullName
    .replace(' FC', '')
    .replace(' CF', '')
    .replace(' SC', '')
    .replace(' CD', '')
    .replace(' AFC', '')
    .replace(' & Hove Albion', '');

  // For soccer leagues, keep the full shortened name
  if (['Premier League', 'La Liga', 'Serie A', 'MLS', 'Champions League', 'Europa League', 'Bundesliga', 'English Championship'].includes(league)) {
    return shortName;
  }

  // But only for non-soccer teams
  const words = shortName.split(' ');
  if (words.length > 1) {
    return words[words.length - 1];
  }
  
  return shortName;
}; 