// src/services/youtubeApi.js

import { useApp } from '../contexts/AppContext';
import { youtubeAuthService } from './youtubeAuthService';
import { LEAGUES } from '../config/leagues';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

// Sport-specific formatting for better search results
const SPORT_FORMATS = {
  soccer: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  hockey: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  basketball: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  football: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  baseball: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`
};

const SPORT_BY_LEAGUE = {
  'NBA': 'basketball',
  'NFL': 'football',
  'MLB': 'baseball',
  'Premier League': 'soccer',
  'La Liga': 'soccer',
  'Serie A': 'soccer',
  'NHL': 'hockey',
  'Champions League': 'soccer',
  'Europa League': 'soccer',
  'Bundesliga': 'soccer',
  'Men\'s College Basketball': 'basketball',
  'Women\'s College Basketball': 'basketball',
  'College Football': 'football',
  'Turkish Super Lig': 'soccer',
  'Scottish Premiership': 'soccer',
  'English Championship': 'soccer'
};

const highlightCache = new Map();

const HIGHLIGHT_DELAY_HOURS = 3; // Wait at least 3 hours after game end

const GAME_DELAYS = {
  // Soccer leagues
  'Premier League': 3,
  'La Liga': 3,
  'Serie A': 3,
  'Bundesliga': 3,
  'Champions League': 3,
  'Europa League': 3,
  'MLS': 3,
  'Turkish Super Lig': 3,
  'Scottish Premiership': 3,
  'Ligue 1': 3,
  'Eredivisie': 3,
  'English Championship': 3,
  
  // Other sports
  'NFL': 4.5,  // 4.5 hours after kickoff
  'NBA': 3.5,  // 3.5 hours after tipoff
  'MLB': 3.5,  // 3.5 hours after first pitch
  'NHL': 3.5,  // 3.5 hours after puck drop
  
  // College sports
  'Men\'s College Basketball': 3.5,
  'Women\'s College Basketball': 3.5,
  'College Football': 4.5,
  
  // Default delay if not specified
  'DEFAULT': 3
};

export const youtubeApi = {
  isHighlightAvailable(game) {
    // Check if league is supported
    if (!game.league) {
      return false;
    }

    const now = new Date();
    const gameDate = new Date(game.rawDate);
    
    // Get the delay for this league, or use default
    const delayHours = GAME_DELAYS[game.league] || GAME_DELAYS.DEFAULT;
    
    // Calculate hours since game started
    const hoursSinceStart = (now - gameDate) / (1000 * 60 * 60);
    
    // For completed games, check if enough time has passed
    if (game.status === 'Final') {
      return hoursSinceStart >= delayHours;
    }
    
    // For games not marked as Final, be more conservative
    return hoursSinceStart >= (delayHours + 1);
  },

  async getHighlightVideo(homeTeam, awayTeam, rawDate, league, channelSettings) {
    try {
      // For authenticated users with channel settings
      if (channelSettings?.[league]) {
        const result = await this.searchWithBackoff(
          `${homeTeam} vs ${awayTeam} ${channelSettings[league]?.searchParam || 'highlights'}`,
          { homeTeam, awayTeam, rawDate, league, channelSettings }
        );

        if (result.type === 'video') {
          return {
            type: 'direct',
            videoId: result.videoId
          };
        }
      }

      // For unauthenticated users or if no channel settings found
      // Use a simplified but similar scoring approach
      const searchQuery = this.getOptimizedSearchQuery(homeTeam, awayTeam, league);
      return {
        type: 'search',
        searchQuery
      };
    } catch (error) {
      console.error('Error fetching highlights:', error);
      return {
        type: 'search',
        searchQuery: `${homeTeam} vs ${awayTeam} highlights`
      };
    }
  },

  getOptimizedSearchQuery(homeTeam, awayTeam, league) {
    // Use sport-specific formatting if available
    const sport = SPORT_BY_LEAGUE[league];
    const formatter = SPORT_FORMATS[sport];
    
    const baseQuery = formatter 
      ? formatter(homeTeam, awayTeam)
      : `${homeTeam} vs ${awayTeam}`;

    // Add league-specific keywords
    const leagueKeywords = {
      'Premier League': 'EPL',
      'Champions League': 'UCL',
      'Europa League': 'UEL',
      // Add more league-specific acronyms as needed
    };

    const leagueTag = leagueKeywords[league] || league;
    
    // Return raw query string (don't encode here)
    return `${baseQuery} ${leagueTag} highlights extended`;
  },

  async searchWithBackoff(query, gameInfo, attempt = 1) {
    const MAX_ATTEMPTS = 3;
    const BACKOFF_MS = 1000 * Math.pow(2, attempt - 1);

    try {
      await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));
      return await this.makeSearchRequest(query, gameInfo);
    } catch (error) {
      if (error.status === 429 && attempt < MAX_ATTEMPTS) {
        await new Promise(resolve => setTimeout(resolve, BACKOFF_MS));
        return this.searchWithBackoff(query, gameInfo, attempt + 1);
      }
      throw error;
    }
  },

  async makeSearchRequest(query, gameInfo) {
    try {
      // Get auth token from the authenticated session
      const token = youtubeAuthService.getToken();
      
      // Base URL and params
      const baseUrl = 'https://www.googleapis.com/youtube/v3/search';
      const params = new URLSearchParams({
        part: 'snippet',
        maxResults: '10',
        q: query,
        // Only add API key if not authenticated
        ...((!token && { key: 'AIzaSyCiHfeSKjSrE1WmOYyG9VN7del58roK9zo' }))
      });

      const url = `${baseUrl}?${params}`;
      
      // Headers based on auth state
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      };

      console.log('🔍 Starting YouTube search:', {
        isAuthenticated: !!token,
        query,
        gameInfo,
        hasChannelSettings: !!gameInfo.channelSettings
      });

      const response = await fetch(url, { headers });
      
      // Add response debugging
      console.log('📡 API Response:', {
          status: response.status,
          ok: response.ok
      });

      if (!response.ok) {
          const errorData = await response.json();
          console.error('❌ YouTube API Error:', errorData);
          throw new Error(`YouTube API error: ${errorData.error?.message || 'Unknown error'}`);
      }

      const data = await response.json();
      
      // Log the response data
      console.log('📦 API Data:', {
          itemCount: data.items?.length,
          quotaUsed: data.pageInfo?.totalResults
      });

      // Get user's configured channels for this league
      const userChannels = gameInfo.channelSettings[gameInfo.league]?.channels || [];
      
      console.log('🎥 Search Configuration:', {
        query,
        league: gameInfo.league,
        userConfiguredChannels: userChannels
      });

      // Score each video based on user's channel preferences
      const scoredVideos = data.items.map(video => {
        const title = video.snippet.title.toLowerCase();
        const channelTitle = video.snippet.channelTitle.toLowerCase();
        const publishedAt = new Date(video.snippet.publishedAt);
        const gameDate = new Date(gameInfo.rawDate);
        const hoursSinceGame = (publishedAt - gameDate) / (1000 * 60 * 60);
        let score = 0;

        // Only consider videos published within 24 hours after the game
        if (hoursSinceGame < 0 || hoursSinceGame > 24) {
          return { score: 0, video, title, channel: channelTitle };
        }

        // Base scoring for all videos
        score += this.getBaseScore(title, channelTitle, video, gameInfo);

        // Additional scoring for authenticated users with channel preferences
        if (gameInfo.channelSettings?.[gameInfo.league]?.channels) {
          score += this.getChannelPreferenceScore(channelTitle, gameInfo);
        }

        console.log('Score calculated:', {
          title,
          channelTitle,
          score,
          hoursSinceGame,
          duration: video.contentDetails?.duration
        });

        return {
          score,
          video,
          title,
          channel: channelTitle,
          publishedAt
        };
      });

      // Sort by score and return best match
      const bestMatch = scoredVideos.sort((a, b) => b.score - a.score)[0];
      
      console.log('🏆 Selected Match:', {
        title: bestMatch.title,
        channel: bestMatch.channel,
        matchedUserChannel: bestMatch.matchedUserChannel,
        score: bestMatch.score
      });

      // If we found a good match (score > 50), return it directly
      if (bestMatch.score > 50) {
        return {
          type: 'video',
          videoId: bestMatch.video.id.videoId
        };
      }

      // If no good match, show warning dialog and return search
      const gameDate = this.formatGameDate(new Date(gameInfo.rawDate));
      const searchQuery = `${gameInfo.homeTeam} vs ${gameInfo.awayTeam} highlights ${gameDate}`;
      
      await this.showNoHighlightsDialog();
      
      return {
        type: 'search',
        searchQuery: encodeURIComponent(searchQuery)
      };
    } catch (error) {
      console.error('YouTube API Error:', error);
      throw error;
    }
  },

  getBaseScore(title, channelTitle, video, gameInfo) {
    let score = 0;
    const titleLower = title.toLowerCase();
    const channelLower = channelTitle.toLowerCase();
    const homeTeamLower = gameInfo.homeTeam.toLowerCase();
    const awayTeamLower = gameInfo.awayTeam.toLowerCase();
    const leagueLower = gameInfo.league.toLowerCase();

    // Must have highlights in the title (including international variations)
    if (!titleLower.includes('highlight') && !titleLower.includes('özet')) {
      return 0;
    }

    // Core scoring: Title relevance
    // Exact match for "Team A vs Team B" or "Team A - Team B"
    if (titleLower.includes(`${homeTeamLower} vs ${awayTeamLower}`) || 
        titleLower.includes(`${homeTeamLower} - ${awayTeamLower}`)) {
      score += 40;
    } else {
      // Partial matches for team names
      if (titleLower.includes(homeTeamLower)) score += 15;
      if (titleLower.includes(awayTeamLower)) score += 15;
    }

    // Quality indicators in title
    if (titleLower.includes('extended')) score += 15;
    if (titleLower.includes('full')) score += 10;
    if (titleLower.includes('official')) score += 10;

    // Penalize likely non-highlight content
    if (titleLower.includes('reaction')) score -= 30;
    if (titleLower.includes('preview')) score -= 30;
    if (titleLower.includes('fifa')) score -= 50;
    if (titleLower.includes('gameplay')) score -= 50;

    // Channel authority scoring
    const leagueConfig = Object.values(LEAGUES).find(l => l.name === gameInfo.league);
    
    // Get user-configured channels for this league
    const userChannels = gameInfo.channelSettings[gameInfo.league]?.channels || [];
    
    // Get default channels from LEAGUES config
    const defaultChannels = leagueConfig?.channels || [];
    
    // Combine channels with user channels taking priority
    const allChannels = [...userChannels, ...defaultChannels];
    
    // Score based on channel priority
    for (let i = 0; i < allChannels.length; i++) {
      const channel = allChannels[i].toLowerCase();
      if (channelLower.includes(channel)) {
        // User-configured channels (first in array) get higher scores
        const isUserChannel = i < userChannels.length;
        score += isUserChannel ? 85 - (i * 10) : 30 - (i * 5);
        break;
      }
    }

    // League relevance in channel name
    if (channelLower.includes(leagueLower)) {
      score += 20;
    }

    // Team relevance in channel name
    if (channelLower.includes(homeTeamLower) || channelLower.includes(awayTeamLower)) {
      score += 15;
    }

    // Penalize very short titles (likely not proper highlights)
    if (title.length < 20) {
      score -= 20;
    }

    console.log('Scoring details:', {
      title,
      channel: channelTitle,
      baseScore: score,
      hasHighlights: titleLower.includes('highlight') || titleLower.includes('özet'),
      exactTeamMatch: titleLower.includes(`${homeTeamLower} vs ${awayTeamLower}`),
      isUserConfiguredChannel: userChannels.some(c => channelLower.includes(c.toLowerCase())),
      isDefaultChannel: defaultChannels.some(c => channelLower.includes(c.toLowerCase()))
    });

    return score;
  },

  getChannelPreferenceScore(channelTitle, gameInfo) {
    let score = 0;
    const userChannels = gameInfo.channelSettings[gameInfo.league]?.channels || [];
    const channelLower = channelTitle.toLowerCase();

    // Check against each user channel in priority order
    for (let i = 0; i < userChannels.length; i++) {
      const userChannel = userChannels[i].toLowerCase();
      if (channelLower.includes(userChannel)) {
        // Higher priority channels get more points
        score += Math.max(50, 100 - (i * 15));
        break;
      }
    }

    return score;
  },

  openVideoInModal(videoId) {
    const modal = document.createElement('div');
    modal.innerHTML = `
      <div class="modal">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>
      </div>
    `;
    document.body.appendChild(modal);
  },

  formatGameDate(date) {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric',
      month: 'long', 
      day: 'numeric'
    });
  },

  // Fallback method to handle bot detection/embedding issues
  handleVideoError(videoId, homeTeam, awayTeam) {
    // Return a direct link to YouTube search
    const searchQuery = `${homeTeam} vs ${awayTeam} highlights`;
    const searchUrl = `https://www.youtube.com/results?search_query=${encodeURIComponent(searchQuery)}`;
    
    // Open in new tab instead of embedded player
    window.open(searchUrl, '_blank');
    return true;
  },

  showSpoilerCoverDialog() {
    return new Promise((resolve) => {
      const dialog = document.createElement('div');
      dialog.className = 'fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4';
      
      const content = `
        <div class="bg-white rounded-lg p-6 max-w-md">
          <h3 class="text-lg font-semibold mb-4">Opening Highlights</h3>
          <p class="text-gray-600 mb-6">
            We'll open this directly in YouTube to protect you from spoilers in the title and thumbnail! 🙈
          </p>
          <div class="flex justify-end gap-4">
            <button 
              class="cancel-btn px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button 
              class="continue-btn px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded"
            >
              Continue to YouTube
            </button>
          </div>
        </div>
      `;
      
      dialog.innerHTML = content;
      
      const handleClose = (confirmed) => {
        dialog.remove();
        resolve(confirmed);
      };

      dialog.querySelector('.cancel-btn').addEventListener('click', () => handleClose(false));
      dialog.querySelector('.continue-btn').addEventListener('click', () => handleClose(true));

      document.body.appendChild(dialog);
    });
  },

  async showNoHighlightsDialog() {
    return new Promise((resolve) => {
      const dialog = document.createElement('div');
      dialog.className = 'fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4';
      
      const content = `
        <div class="bg-white rounded-lg p-6 max-w-md">
          <h3 class="text-lg font-semibold mb-4">⚠️ No Verified Highlights Found</h3>
          <p class="text-gray-600 mb-6">
            We couldn't find a reliable highlight video for this game using our awesome algorithm. We'll redirect you to YouTube search instead.
          </p>
          <div class="flex justify-end gap-4">
            <button 
              class="cancel-btn px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button 
              class="continue-btn px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded"
            >
              Continue to Search
            </button>
          </div>
        </div>
      `;
      
      dialog.innerHTML = content;
      
      const handleClose = (confirmed) => {
        dialog.remove();
        resolve(confirmed);
      };

      dialog.querySelector('.cancel-btn').addEventListener('click', () => handleClose(false));
      dialog.querySelector('.continue-btn').addEventListener('click', () => handleClose(true));

      document.body.appendChild(dialog);
    });
  },
};