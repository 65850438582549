// Add API_BASE_URL at the top of the file
const API_BASE_URL = 'https://site.api.espn.com/apis/site/v2/sports';

// First, define the LEAGUES constant
const LEAGUES = {
  // Basketball
  NBA: { 
    id: 'nba', 
    name: 'NBA',
    shortName: 'NBA',
    sport: 'basketball',
    emoji: '🏀',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/leagues/500/nba.png',
    channels: ['House of Highlights', 'NBA', 'ESPN'],
    searchParam: 'NBA Highlights',
    officialChannel: 'UCWJ2lWNubArHWmf3FIHbfcQ',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'nba',
    displayPriority: 1
  },
  WNBA: { 
    id: 'wnba', 
    name: 'WNBA',
    shortName: 'WNBA',
    sport: 'basketball',
    emoji: '🏀',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/wnba.png',
    channels: ['WNBA', 'ESPN'],
    searchParam: 'WNBA Highlights',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'wnba',
    displayPriority: 2
  },
  NCAAB_MENS: { 
    id: 'mens-college-basketball', 
    name: "Men's College Basketball",
    shortName: "NCAAM",
    sport: 'basketball',
    emoji: '',
    logo: '/images/NCAA_logo.svg',
    channels: ['NCAA March Madness', 'ESPN'],
    searchParam: 'NCAA Basketball Highlights',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'mens-college-basketball',
    isCollege: true,
    displayPriority: 3
  },
  NCAAB_WOMENS: { 
    id: 'womens-college-basketball', 
    name: "Women's College Basketball",
    shortName: "NCAAW",
    sport: 'basketball',
    emoji: '🏀',
    logo: '/images/NCAA_logo.svg',
    channels: ['NCAA March Madness', 'ESPN'],
    searchParam: 'NCAA Women Basketball Highlights',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'womens-college-basketball',
    isCollege: true,
    displayPriority: 4
  },

  // Football
  NFL: { 
    id: 'nfl', 
    name: 'NFL',
    shortName: 'NFL',
    sport: 'football',
    emoji: '🏈',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/leagues/500/nfl.png',
    channels: ['NFL', 'NFL Network', 'ESPN'],
    searchParam: 'NFL Highlights',
    officialChannel: 'UCDVYQ4Zhbm3S2dlz7P1GBDg',
    highlightDelay: 3,
    espnCategory: 'football',
    espnLeague: 'nfl',
    displayPriority: 1
  },
  NCAAF: { 
    id: 'college-football', 
    name: 'College Football',
    shortName: 'CFB',
    sport: 'football',
    emoji: '🏈',
    channels: ['ESPN College Football', 'ESPN'],
    searchParam: 'NCAA Football Highlights',
    highlightDelay: 3,
    espnCategory: 'football',
    espnLeague: 'college-football',
    isCollege: true,
    displayPriority: 2
  },

  // Baseball
  MLB: { 
    id: 'mlb', 
    name: 'MLB',
    shortName: 'MLB',
    sport: 'baseball',
    emoji: '⚾',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/mlb.png',
    channels: ['MLB', 'ESPN'],
    searchParam: 'MLB Highlights',
    highlightDelay: 3,
    espnCategory: 'baseball',
    espnLeague: 'mlb',
    displayPriority: 1
  },

  // Hockey
  NHL: { 
    id: 'nhl', 
    name: 'NHL',
    shortName: 'NHL',
    sport: 'hockey',
    emoji: '🏒',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/nhl.png',
    channels: ['NHL', 'SPORTSNET'],
    searchParam: 'NHL Highlights',
    officialChannel: 'UCqFMzb-4AUf6WAIbl132QKA',
    highlightDelay: 3,
    espnCategory: 'hockey',
    espnLeague: 'nhl',
    displayPriority: 1
  },

  // Soccer - International
  WORLD_CUP: { 
    id: 'fifa.world', 
    name: 'FIFA World Cup',
    shortName: 'WC',
    sport: 'soccer',
    category: 'international',
    emoji: '🏆',
    logo: '/images/fifa.svg',
    channels: ['FIFA', 'FOX Soccer'],
    searchParam: 'FIFA World Cup Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'fifa.world',
    isInternational: true,
    displayPriority: 1,
    relatedLeagues: [
      'fifa.wwc',
      'uefa.nations',
      'uefa.w.nations',
      'caf.nations',
      'concacaf.nations.league',
      'concacaf.womens.championship'
    ],
  },
  WOMENS_WORLD_CUP: { 
    id: 'fifa.wwc',
    name: 'FIFA Women\'s World Cup',
    shortName: 'WWC',
    sport: 'soccer',
    category: 'international',
    emoji: '🏆',
    logo: '/images/fifa.svg',
    channels: ['FIFA', 'FOX Soccer'],
    searchParam: 'FIFA Women World Cup Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'fifa.wwc',
    isInternational: true,
    displayPriority: 2,
    relatedLeagues: [
      'fifa.world',
      'uefa.nations',
      'uefa.w.nations',
      'caf.nations',
      'concacaf.nations.league',
      'concacaf.womens.championship'
    ],
  },
  UEFA_NATIONS: {
    id: 'uefa.nations',
    name: 'UEFA Nations League',
    shortName: 'UNL',
    sport: 'soccer',
    category: 'international',
    emoji: '🇪🇺',
    logo: '/images/uefa.png',
    channels: ['FOX Sports', 'UEFA'],
    searchParam: 'UEFA Nations League Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'uefa.nations',
    isInternational: true,
    displayPriority: 3,
    relatedLeagues: ['uefa.w.nations', 'fifa.world', 'fifa.wwc'],
  },
  UEFA_WOMENS_NATIONS: {
    id: 'uefa.w.nations',
    name: 'UEFA Women\'s Nations League',
    shortName: 'UWNL',
    sport: 'soccer',
    category: 'international',
    emoji: '🇪🇺',
    logo: '/images/uefa.png',
    channels: ['UEFA', 'DAZN'],
    searchParam: 'UEFA Women Nations League Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'uefa.w.nations',
    isInternational: true,
    displayPriority: 4,
    relatedLeagues: ['uefa.nations', 'fifa.world', 'fifa.wwc'],
  },
  CAF_NATIONS: {
    id: 'caf.nations',
    name: 'Africa Cup of Nations',
    shortName: 'AFCON',
    sport: 'soccer',
    category: 'international',
    emoji: '🌍',
    logo: '/images/caf.png',
    channels: ['beIN SPORTS', 'CAF TV'],
    searchParam: 'AFCON Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'caf.nations',
    isInternational: true,
    displayPriority: 5,
    relatedLeagues: ['fifa.world', 'fifa.wwc'],
  },
  CONCACAF_NATIONS: {
    id: 'concacaf.nations.league',
    name: 'CONCACAF Nations League',
    shortName: 'CNL',
    sport: 'soccer',
    category: 'international',
    emoji: '🌎',
    logo: '/images/Concacaf.png',
    channels: ['CBS Sports', 'Paramount+'],
    searchParam: 'CONCACAF Nations League Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'concacaf.nations.league',
    isInternational: true,
    displayPriority: 6,
    relatedLeagues: ['concacaf.womens.championship', 'fifa.world', 'fifa.wwc'],
  },
  CONCACAF_WOMENS: {
    id: 'concacaf.womens.championship',
    name: 'CONCACAF W Championship',
    shortName: 'CWC',
    sport: 'soccer',
    category: 'international',
    emoji: '🌎',
    logo: '/images/Concacaf.png',
    channels: ['CBS Sports', 'Paramount+'],
    searchParam: 'CONCACAF W Championship Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'concacaf.womens.championship',
    isInternational: true,
    displayPriority: 7,
    relatedLeagues: ['concacaf.nations.league', 'fifa.world', 'fifa.wwc'],
  },
  CHAMPIONS_LEAGUE: { 
    id: 'uefa.champions', 
    name: 'UEFA Champions League',
    shortName: 'UCL',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/2.png',
    channels: ['CBS Sports Golazo', 'UEFA Champions League'],
    searchParam: 'Champions League Highlights CBS Sports',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'uefa.champions',
    displayPriority: 1,
    relatedLeagues: ['uefa.europa', 'uefa.europa.conf', 'eng.1', 'esp.1', 'ita.1', 'ger.1', 'fra.1', 'ned.1'],
  },
  EUROPA_LEAGUE: {
    id: 'uefa.europa',
    name: 'UEFA Europa League',
    shortName: 'UEL',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/2310.png',
    channels: ['CBS Sports Golazo'],
    searchParam: 'Europa League Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'uefa.europa',
    displayPriority: 2,
    relatedLeagues: ['uefa.champions', 'uefa.europa.conf', 'eng.1', 'esp.1', 'ita.1', 'ger.1', 'fra.1', 'ned.1'],
  },
  EUROPA_CONFERENCE_LEAGUE: {
    id: 'uefa.europa.conf',
    name: 'UEFA Europa Conference League',
    shortName: 'UECL',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: '/images/uefa-conf.png',
    channels: ['CBS Sports Golazo'],
    searchParam: 'Conference League Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'uefa.europa.conf',
    displayPriority: 3,
    relatedLeagues: ['uefa.champions', 'uefa.europa', 'eng.1', 'esp.1', 'ita.1', 'ger.1', 'fra.1', 'ned.1'],
  },
  FA_CUP: {
    id: 'eng.fa',
    name: 'FA Cup',
    shortName: 'FA',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/4.png',
    channels: ['ESPN+'],
    searchParam: 'FA Cup Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.fa',
    displayPriority: 4,
    country: 'England',
    relatedLeagues: ['eng.1', 'eng.2', 'eng.league_cup'],
  },
  CARABAO_CUP: {
    id: 'eng.league_cup',
    name: 'Carabao Cup',
    shortName: 'EFL',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/5.png',
    channels: ['CBS Sports Golazo', 'ESPN FC', 'NBC Sports'],
    searchParam: 'Carabao Cup Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.league_cup',
    displayPriority: 5,
    country: 'England',
    relatedLeagues: ['eng.1', 'eng.2', 'eng.fa'],
  },
  COPA_DEL_REY: {
    id: 'esp.copa_del_rey',
    name: 'Copa del Rey',
    shortName: 'CDR',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/17.png',
    channels: ['ESPN+'],
    searchParam: 'Copa del Rey Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'esp.copa_del_rey',
    displayPriority: 6,
    country: 'Spain',
    relatedLeagues: ['esp.1'],
  },
  COPPA_ITALIA: {
    id: 'ita.coppa_italia',
    name: 'Coppa Italia',
    shortName: 'CI',
    sport: 'soccer',
    category: 'tournaments',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/11.png',
    channels: ['Paramount+'],
    searchParam: 'Coppa Italia Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ita.coppa_italia',
    displayPriority: 7,
    country: 'Italy',
    relatedLeagues: ['ita.1'],
  },

  // Soccer - League Competitions
  PREMIER_LEAGUE: { 
    id: 'eng.1', 
    name: 'Premier League',
    shortName: 'EPL',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/23.png',
    channels: ['NBC Sports', 'Peacock', 'Premier League', 'Sky Sports Football'],
    searchParam: 'Premier League Highlights NBC',
    officialChannel: 'UCqZQlzSHbVJrwrn5XvzrzcA',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.1',
    displayPriority: 2,
    country: 'England',
    relatedLeagues: ['eng.2', 'eng.fa', 'eng.league_cup', 'uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  WOMENS_SUPER_LEAGUE: {
    id: 'eng.w.1',
    name: 'Women\'s Super League',
    shortName: 'WSL',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    logo: '/images/WSL.png',
    channels: ['Sky Sports WSL', 'BBC Sport'],
    searchParam: 'WSL Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.w.1',
    displayPriority: 2.5,
    country: 'England',
    relatedLeagues: [],
  },
  LA_LIGA: { 
    id: 'esp.1', 
    name: 'La Liga',
    shortName: 'Liga',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🇪🇸',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/15.png',
    channels: ['ESPN FC', 'beIN SPORTS'],
    searchParam: 'La Liga Highlights',
    officialChannel: 'UCTv-XvfzLX3i4IGWAm4sbmA',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'esp.1',
    displayPriority: 3,
    country: 'Spain',
    relatedLeagues: ['esp.copa_del_rey', 'uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  SERIE_A: {
    id: 'ita.1',
    name: 'Serie A',
    shortName: 'SA',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🇮🇹',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/12.png',
    channels: ['CBS Sports Golazo'],
    searchParam: 'Serie A Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ita.1',
    displayPriority: 4,
    country: 'Italy',
    relatedLeagues: ['ita.coppa_italia', 'uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  BUNDESLIGA: {
    id: 'ger.1',
    name: 'Bundesliga',
    shortName: 'BL',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🇩🇪',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/10.png',
    channels: ['ESPN FC', 'Bundesliga'],
    searchParam: 'Bundesliga Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ger.1',
    displayPriority: 5,
    country: 'Germany',
    relatedLeagues: ['ger.dfb', 'uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  LIGUE_1: {
    id: 'fra.1',
    name: 'Ligue 1',
    shortName: 'L1',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🇫🇷',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/9.png',
    channels: ['beIN SPORTS'],
    searchParam: 'Ligue 1 Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'fra.1',
    displayPriority: 6,
    country: 'France',
    relatedLeagues: ['fra.coupe', 'uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  EREDIVISIE: {
    id: 'ned.1',
    name: 'Eredivisie',
    shortName: 'ERE',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🇳🇱',
    logo: '/images/eredivisie.svg',
    channels: ['ESPN'],
    searchParam: 'Eredivisie Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ned.1',
    displayPriority: 7,
    country: 'Netherlands',
    relatedLeagues: ['uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  MLS: {
    id: 'usa.1',
    name: 'MLS',
    shortName: 'MLS',
    sport: 'soccer',
    category: 'leagues',
    emoji: '⚽',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/mls.png',
    channels: ['MLS', 'Apple TV'],
    searchParam: 'MLS Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'usa.1',
    displayPriority: 8,
    country: 'United States',
    relatedLeagues: [],
  },
  SUPER_LIG: {
    id: 'tur.1',
    name: 'Turkish Super Lig',
    shortName: 'TSL',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🇹🇷',
    channels: ['beIN SPORTS'],
    searchParam: 'Super Lig Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'tur.1',
    displayPriority: 9,
    country: 'Turkey',
    relatedLeagues: ['uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  SCOTTISH_PREMIERSHIP: {
    id: 'sco.1',
    name: 'Scottish Premiership',
    shortName: 'SPL',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    logo: '/images/scot.svg',
    channels: ['Sky Sports Football'],
    searchParam: 'Scottish Premiership Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'sco.1',
    displayPriority: 10,
    country: 'Scotland',
    relatedLeagues: ['uefa.champions', 'uefa.europa', 'uefa.europa.conf'],
  },
  ENGLISH_CHAMPIONSHIP: {
    id: 'eng.2',
    name: 'English Championship',
    shortName: 'EFL',
    sport: 'soccer',
    category: 'leagues',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    channels: ['Sky Sports Football'],
    searchParam: 'Championship Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.2',
    displayPriority: 11,
    country: 'England',
    relatedLeagues: ['eng.1', 'eng.fa', 'eng.league_cup'],
  }
};

// Helper function to get leagues grouped by sport
const getLeaguesByGroup = () => {
  const groups = {};
  
  Object.values(LEAGUES).forEach(league => {
    const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
    if (!groups[sport]) {
      groups[sport] = {
        name: sport,
        emoji: league.emoji,
        leagues: {}
      };
    }

    // For soccer, handle subcategories
    if (sport === 'Soccer') {
      // Determine category - default to 'Leagues' if not specified
      const categoryKey = league.category?.toUpperCase() || 'CLUB';
      
      // Initialize subcategories if needed
      if (!groups[sport].subCategories) {
        groups[sport].subCategories = {
          'LEAGUES': {
            name: 'Leagues',
            leagues: {}
          },
          'INTERNATIONAL': {
            name: 'International',
            leagues: {}
          },
          'TOURNAMENTS': {
            name: 'Tournaments & Cups',
            leagues: {}
          }
        };
      }

      // Add league to appropriate category
      if (groups[sport].subCategories[categoryKey]) {
        groups[sport].subCategories[categoryKey].leagues[league.name] = {
          league: league.id,
          emoji: league.emoji || '⚾',
          logo: league.logo
        };
      }
    } else {
      // For non-soccer leagues
      groups[sport].leagues[league.name] = {
        league: league.id,
        emoji: league.emoji,
        logo: league.logo
      };
    }
  });

  return groups;
};

// Helper function to get ESPN API configuration for a league
const getESPNConfig = (leagueId) => {
  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
  if (!league) return null;

  return {
    category: league.espnCategory,
    league: league.espnLeague,
    isCollege: league.isCollege || false,
    isInternational: league.isInternational || false
  };
};

// Helper function to get display name for a team based on league
const getTeamDisplayName = (team, leagueId) => {
  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
  if (!league) return team.displayName;

  if (league.isCollege) {
    return `${team.location} ${team.name}`;
  }
  return team.displayName;
};

// Helper function to get ESPN API URL for a league
const getLeagueApiUrl = (leagueId, endpoint = '') => {
  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
  if (!league) return null;
  return `${API_BASE_URL}/${league.sport}/${league.id}${endpoint}`;
};

// Helper function to process game status consistently
const processGameStatus = (status) => {
  const statusMap = {
    'STATUS_SCHEDULED': 'Upcoming',
    'STATUS_IN_PROGRESS': 'In Progress',
    'STATUS_FINAL': 'Final',
    'STATUS_FULL_TIME': 'Final',
    // Add more mappings as needed
  };
  return statusMap[status.type.name] || status.type.name;
};

// Helper function to format team data consistently
const formatTeamData = (team, league) => ({
  id: `${league.sport}-${team.team.id}`,
  name: team.team.displayName,
  logo: team.team.logos?.[0]?.href || 
        team.team.logo || 
        team.team?.links?.find(link => link.rel.includes('logo'))?.href || null,
  score: team.score
});

export const initializeLeagueLogos = () => {
    // function implementation
}

export const getRelatedTeams = (teamId) => {
  const [sport, league, teamNumber] = teamId.split('-');
  if (sport !== 'soccer') return [];

  // Find the current league in our LEAGUES object
  const currentLeague = Object.values(LEAGUES).find(l => l.id === league);
  if (!currentLeague) return [];

  // Get all leagues that either:
  // 1. Are related to the current league
  // 2. Have the current league in their related leagues
  const relatedLeagueIds = new Set([
    // Direct relationships from current league
    ...(currentLeague.relatedLeagues || []),
    // Reverse relationships from other leagues
    ...Object.values(LEAGUES)
      .filter(l => l.relatedLeagues?.includes(league))
      .map(l => l.id)
  ]);

  // Return team IDs for all related leagues
  return Array.from(relatedLeagueIds).map(relatedLeague => 
    `soccer-${relatedLeague}-${teamNumber}`
  );
};

export {
  LEAGUES,
  getLeaguesByGroup,
  getESPNConfig,
  getTeamDisplayName,
  getLeagueApiUrl,
  processGameStatus,
  formatTeamData,
  API_BASE_URL
}; 