import React, { useState, useEffect, useMemo } from 'react';
import { ChevronDown, ChevronUp, Plus, X, Save, GripVertical } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LEAGUES } from '../config/leagues';
import { Link } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';

// Add this constant for consistent sorting
const SPORT_DISPLAY_ORDER = {
  Basketball: 1,
  Football: 2,
  Baseball: 3,
  Hockey: 4,
  Soccer: 5
};

// Add this constant near the top with other constants
const SPORT_EMOJIS = {
  Basketball: '🏀',
  Football: '🏈',
  Baseball: '⚾',
  Hockey: '🏒',
  Soccer: '⚽'
};

// Update how we look up leagues
const findLeagueConfig = (leagueKey) => {
  // First try direct lookup in LEAGUES
  if (LEAGUES[leagueKey]) {
    return LEAGUES[leagueKey];
  }

  // If not found, try to find by name
  return Object.values(LEAGUES).find(league => 
    league.name === leagueKey || 
    league.shortName === leagueKey ||
    league.id === leagueKey
  );
};

// Debug helper
const getLeagueConfig = (leagueKey) => {
  const config = findLeagueConfig(leagueKey);
  if (!config) {
    console.warn(`No league configuration found for: ${leagueKey}`, {
      searchKey: leagueKey,
      availableLeagues: Object.values(LEAGUES).map(l => ({
        id: l.id,
        name: l.name,
        shortName: l.shortName
      }))
    });
    return null;
  }
  return config;
};

// Helper function to add a league to the grouped structure
const addLeagueToGroups = (acc, league, settings) => {
  const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
  
  // Initialize sport group if it doesn't exist
  if (!acc[sport]) {
    acc[sport] = {
      name: sport,
      emoji: league.emoji,
      leagues: {}
    };
  }

  // Handle soccer's special categorization
  if (sport === 'Soccer' && league.category) {
    if (!acc[sport].subCategories) {
      acc[sport].subCategories = {
        'International': { name: 'International', leagues: {} },
        'Leagues': { name: 'Leagues', leagues: {} },
        'Tournaments': { name: 'Tournaments & Cups', leagues: {} }
      };
    }

    const categoryMap = {
      'international': 'International',
      'leagues': 'Leagues',
      'tournaments': 'Tournaments'
    };
    
    const category = categoryMap[league.category.toLowerCase()] || 'Leagues';
    
    if (acc[sport].subCategories[category]) {
      acc[sport].subCategories[category].leagues[league.name] = {
        ...settings,
        logo: league.logo,
        emoji: league.emoji,
        channels: league.channels || [] // Ensure channels are included from league config
      };
    }
  } else {
    acc[sport].leagues[league.name] = {
      ...settings,
      logo: league.logo,
      emoji: league.emoji,
      channels: league.channels || [] // Ensure channels are included from league config
    };
  }

  return acc;
};

// First, let's create a helper function to get unique channels from LEAGUES
const getAllUniqueChannels = () => {
  const channelSet = new Set();
  Object.values(LEAGUES).forEach(league => {
    if (league.channels) {
      league.channels.forEach(channel => channelSet.add(channel));
    }
  });
  return Array.from(channelSet).sort();
};

export default function ChannelsPage() {
  const { channelSettings, updateChannelSettings } = useApp();
  
  // Add debug logging
  console.log('Current channelSettings:', channelSettings);
  console.log('Available LEAGUES:', Object.keys(LEAGUES));

  const [expandedLeagues, setExpandedLeagues] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [expandedLeague, setExpandedLeague] = useState(null);
  
  // Initialize localSettings with ALL leagues from LEAGUES config
  const [localSettings, setLocalSettings] = useState(() => {
    const defaultSettings = Object.values(LEAGUES).reduce((acc, league) => {
      // Use existing settings if available, otherwise use defaults from LEAGUES
      acc[league.name] = {
        channels: channelSettings[league.name]?.channels || league.channels || [],
        searchParam: channelSettings[league.name]?.searchParam || league.searchParam || '',
        logo: league.logo,
        emoji: league.emoji
      };
      return acc;
    }, {});
    return defaultSettings;
  });

  // Group leagues by sport and category
  const groupedSettings = Object.values(LEAGUES).reduce((acc, league) => {
    const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
    
    // Initialize sport group if it doesn't exist
    if (!acc[sport]) {
      acc[sport] = {
        name: sport,
        emoji: league.emoji,
        leagues: {}
      };
    }

    // Handle soccer's special categorization
    if (sport === 'Soccer' && league.category) {
      if (!acc[sport].subCategories) {
        acc[sport].subCategories = {
          'International': { name: 'International', leagues: {} },
          'Leagues': { name: 'Leagues', leagues: {} },
          'Tournaments': { name: 'Tournaments & Cups', leagues: {} }
        };
      }

      const categoryMap = {
        'international': 'International',
        'leagues': 'Leagues',
        'tournaments': 'Tournaments'
      };
      
      const category = categoryMap[league.category.toLowerCase()] || 'Leagues';
      
      if (acc[sport].subCategories[category]) {
        acc[sport].subCategories[category].leagues[league.name] = {
          ...localSettings[league.name],
          logo: league.logo,
          emoji: league.emoji,
          channels: localSettings[league.name]?.channels || league.channels || []
        };
      }
    } else {
      acc[sport].leagues[league.name] = {
        ...localSettings[league.name],
        logo: league.logo,
        emoji: league.emoji,
        channels: localSettings[league.name]?.channels || league.channels || []
      };
    }

    return acc;
  }, {});

  const resetToDefaults = async () => {
    const defaultSettings = Object.values(LEAGUES).reduce((acc, league) => {
      acc[league.name] = {
        channels: league.channels || [],
        searchParam: league.searchParam || '',
        logo: league.logo,
        emoji: league.emoji
      };
      return acc;
    }, {});
    
    setLocalSettings(defaultSettings);
    await updateChannelSettings(defaultSettings);
    setIsDirty(false);
  };

  const toggleLeague = (league) => {
    setExpandedLeagues(prev => ({
      ...prev,
      [league]: !prev[league]
    }));
  };

  const toggleSubCategory = (sportKey, categoryKey) => {
    setExpandedSubCategories(prev => ({
      ...prev,
      [`${sportKey}-${categoryKey}`]: !prev[`${sportKey}-${categoryKey}`]
    }));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    // Extract league name from droppableId
    const leagueName = result.source.droppableId.replace('droppable-', '');
    const items = Array.from(localSettings[leagueName].channels);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setLocalSettings(prev => ({
      ...prev,
      [leagueName]: {
        ...prev[leagueName],
        channels: items
      }
    }));
    setIsDirty(true);
  };

  const addChannel = (league) => {
    setLocalSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: [...prev[league].channels, '']
      }
    }));
    setIsDirty(true);
  };

  const removeChannel = (league, index) => {
    setLocalSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: prev[league].channels.filter((_, i) => i !== index)
      }
    }));
    setIsDirty(true);
  };

  const updateChannel = (league, index, value) => {
    setLocalSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: prev[league].channels.map((channel, i) => 
          i === index ? value : channel
        )
      }
    }));
    setIsDirty(true);
  };

  const updateSearchParam = (league, value) => {
    setLocalSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        searchParam: value
      }
    }));
    setIsDirty(true);
  };

  const saveChanges = async () => {
    try {
      // Clean up the settings object before saving
      const cleanSettings = Object.entries(localSettings).reduce((acc, [league, settings]) => {
        // Only include non-null properties
        acc[league] = {
          channels: settings.channels || [],
          searchParam: settings.searchParam || '',
          // Only include logo and emoji if they exist
          ...(settings.logo && { logo: settings.logo }),
          ...(settings.emoji && { emoji: settings.emoji })
        };
        return acc;
      }, {});

      await updateChannelSettings(cleanSettings);
      setIsDirty(false);
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to save changes. Please try again.');
    }
  };

  const handleLeagueExpand = (leagueName) => {
    setExpandedLeague(expandedLeague === leagueName ? null : leagueName);
  };

  // Debug final grouping
  console.log('Final groupedSettings:', groupedSettings);

  return (
    <div className="max-w-4xl mx-auto py-4 px-2">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="px-6 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-semibold text-gray-900">Channel Settings</h1>
            <div className="flex items-center gap-2">
              <button
                onClick={resetToDefaults}
                className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
              >
                Reset
              </button>
              <button
                onClick={saveChanges}
                disabled={!isDirty}
                className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                  isDirty
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="px-6 pt-6 pb-4">
          <div className="bg-amber-50 border border-amber-200 rounded-md p-3 text-sm">
            <p className="text-amber-700">
              Channel names must match YouTube exactly.{' '}
              <Link to="/about" className="underline hover:text-amber-800">
                Learn more
              </Link>
            </p>
          </div>
        </div>

        <div className="px-6 pb-6">
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="divide-y divide-gray-100">
              {Object.entries(groupedSettings)
                .sort(([sportKeyA], [sportKeyB]) => {
                  return (SPORT_DISPLAY_ORDER[sportKeyA] || 999) - (SPORT_DISPLAY_ORDER[sportKeyB] || 999);
                })
                .map(([sportName, sportGroup]) => (
                  <SportSection
                    key={sportName}
                    sportName={sportName}
                    sportGroup={sportGroup}
                    expanded={expandedLeagues[sportName]}
                    onToggle={() => toggleLeague(sportName)}
                    expandedSubCategories={expandedSubCategories}
                    onToggleSubCategory={toggleSubCategory}
                    expandedLeague={expandedLeague}
                    onLeagueExpand={handleLeagueExpand}
                    {...{ updateSearchParam, addChannel, removeChannel, updateChannel }}
                  />
                ))}
            </div>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

function SportSection({ 
  sportName, 
  sportGroup, 
  expanded, 
  onToggle,
  expandedSubCategories,
  onToggleSubCategory,
  expandedLeague,
  onLeagueExpand,
  ...handlers 
}) {
  return (
    <div className="py-3 first:pt-0 last:pb-0">
      <button
        onClick={onToggle}
        className="w-full px-4 py-2.5 hover:bg-gray-50 flex items-center justify-between rounded-md group"
      >
        <div className="flex items-center gap-3">
          <span className="text-xl">{SPORT_EMOJIS[sportName]}</span>
          <span className="font-semibold text-gray-900">{sportName}</span>
        </div>
        <ChevronDown
          size={18}
          className={`text-gray-400 transition-transform duration-200 ${
            expanded ? 'rotate-180' : ''
          }`}
        />
      </button>

      {expanded && (
        <div className="mt-1 space-y-1">
          {sportName === 'Soccer' && sportGroup.subCategories ? (
            Object.entries(sportGroup.subCategories).map(([category, { name, leagues }]) => (
              <SubCategorySection
                key={category}
                sportName={sportName}
                category={category}
                name={name}
                leagues={leagues}
                expanded={expandedSubCategories[`${sportName}-${category}`]}
                onToggle={() => onToggleSubCategory(sportName, category)}
                expandedLeague={expandedLeague}
                onLeagueExpand={onLeagueExpand}
                {...handlers}
              />
            ))
          ) : (
            <div className="space-y-0.5">
              {Object.entries(sportGroup.leagues).map(([leagueName, settings]) => (
                <LeagueSettings
                  key={leagueName}
                  leagueName={leagueName}
                  settings={settings}
                  expandedLeague={expandedLeague}
                  onLeagueExpand={onLeagueExpand}
                  {...handlers}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function SubCategorySection({ 
  sportName, 
  category, 
  name, 
  leagues, 
  expanded, 
  onToggle,
  expandedLeague,
  onLeagueExpand,
  ...handlers 
}) {
  return (
    <div className="ml-4">
      <button
        onClick={onToggle}
        className="w-full px-4 py-2 hover:bg-gray-50 flex items-center justify-between rounded-md group"
      >
        <span className="font-medium text-gray-700">{name}</span>
        <ChevronDown
          size={16}
          className={`text-gray-400 transition-transform duration-200 ${
            expanded ? 'rotate-180' : ''
          }`}
        />
      </button>

      {expanded && (
        <div className="mt-1 space-y-0.5">
          {Object.entries(leagues).map(([leagueName, settings]) => (
            <LeagueSettings
              key={leagueName}
              leagueName={leagueName}
              settings={settings}
              expandedLeague={expandedLeague}
              onLeagueExpand={onLeagueExpand}
              {...handlers}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function LeagueSettings({ 
  leagueName, 
  settings, 
  expandedLeague,
  onLeagueExpand,
  ...handlers 
}) {
  const league = findLeagueConfig(leagueName);
  const isExpanded = expandedLeague === leagueName;
  
  return (
    <div className={`ml-4 rounded-md transition-colors ${
      isExpanded ? 'bg-gray-50' : ''
    }`}>
      <button
        onClick={() => onLeagueExpand(leagueName)}
        className={`w-full px-4 py-2 hover:bg-gray-100 flex items-center justify-between rounded-md group ${
          isExpanded ? 'bg-gray-100' : ''
        }`}
      >
        <div className="flex items-center gap-2.5">
          {league?.logo ? (
            <img src={league.logo} alt="" className="w-5 h-5 object-contain" />
          ) : (
            <span className="text-lg">{league?.emoji}</span>
          )}
          <span className="text-gray-900">{leagueName}</span>
        </div>
        <ChevronDown
          size={16}
          className={`text-gray-400 transition-transform duration-200 ${
            isExpanded ? 'rotate-180' : ''
          }`}
        />
      </button>

      {isExpanded && (
        <div className="px-4 py-3 space-y-4">
          <div className="space-y-1.5">
            <label className="text-sm font-medium text-gray-700">
              Search Parameter
            </label>
            <input
              type="text"
              value={settings.searchParam || ''}
              onChange={(e) => handlers.onUpdateSearchParam(leagueName, e.target.value)}
              className="w-full px-3 py-2 border rounded-md text-sm"
              placeholder="Enter search parameter..."
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">
                Channels
              </label>
              <button
                onClick={() => handlers.onAddChannel(leagueName)}
                className="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
              >
                <Plus size={14} className="stroke-[2.5]" />
                <span>Add Channel</span>
              </button>
            </div>

            <ChannelList
              leagueName={leagueName}
              channels={settings.channels}
              {...handlers}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function ChannelList({ leagueName, channels = [], onUpdateChannel, onRemoveChannel }) {
  return (
    <Droppable droppableId={`droppable-${leagueName}`}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="space-y-2"
        >
          {channels.map((channel, index) => (
            <Draggable
              key={`${leagueName}-${index}`}
              draggableId={`${leagueName}-${index}`}
              index={index}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className="flex items-center gap-2"
                >
                  <div
                    {...provided.dragHandleProps}
                    className="cursor-grab p-1 hover:bg-gray-100 rounded"
                  >
                    <GripVertical size={16} className="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={channel}
                    onChange={(e) => onUpdateChannel(leagueName, index, e.target.value)}
                    className="flex-1 px-3 py-2 border rounded-md text-sm"
                    placeholder="Enter channel name..."
                  />
                  <button
                    onClick={() => onRemoveChannel(leagueName, index)}
                    className="p-1.5 text-gray-400 hover:text-red-600 hover:bg-gray-100 rounded transition-colors"
                  >
                    <X size={16} />
                  </button>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}