import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, ChevronLeft } from 'lucide-react';

export default function GuestGuide({ onComplete }) {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const steps = [
    {
      content: 'In the top navigation bar, click "Follow" to add your favorite teams and leagues.',
      emoji: '⭐'
    },
    {
      content: 'On any game listing, look for the "+" icon in the top-right corner of the card to expand and see additional games and leagues.',
      emoji: '➕'
    },
    {
      content: 'After a game ends, a "Highlights" button will appear at the bottom of the game card.',
      emoji: '🎥'
    },
    {
      content: 'Look for the bookmark icon in the top-right corner of any game card to save it for later viewing.',
      emoji: '🔖'
    },
    {
      content: 'Access your saved games by clicking "Queue" in the top navigation bar.',
      emoji: '📋'
    },
    {
      content: 'Click "Settings" in the top navigation bar, then find the "Spoiler Mode" toggle in the preferences section.',
      emoji: '👀',
      navigateBeforeShow: true
    }
  ];

  const handleNext = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else if (currentStep < steps.length) {
      const nextStepData = steps[currentStep];
      
      if (nextStepData.navigateBeforeShow) {
        navigate('/preferences');
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      onComplete();
      navigate('/');
    }
  };

  const currentTip = currentStep > 0 ? steps[currentStep - 1] : null;

  return (
    <>
      {/* Dim overlay */}
      {currentStep >= 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-[41] transition-opacity duration-300" />
      )}

      {/* Welcome message */}
      {!currentStep && (
        <div className="fixed inset-0 z-[80] flex items-center justify-center">
          <div className="bg-white rounded-xl shadow-xl p-8 max-w-md mx-4 text-center">
            <h2 className="text-2xl font-bold mb-4">Welcome 👋</h2>
            <p className="text-gray-600 mb-6">Let's take a quick tour of the main features</p>
            <div className="flex gap-4 justify-center">
              <button
                onClick={() => setCurrentStep(1)}
                className="bg-blue-600 text-white rounded-lg py-2 px-6 hover:bg-blue-700 transition-colors"
              >
                Start Tour
              </button>
              <button
                onClick={onComplete}
                className="text-gray-600 hover:text-gray-800 transition-colors"
              >
                Skip for now
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Tour content */}
      {currentStep > 0 && (
        <div className="fixed inset-0 z-[80] flex items-center justify-center">
          <div className="bg-white rounded-xl shadow-xl p-6 max-w-md mx-4">
            <div className="flex gap-3 mb-3">
              <div className="text-xl flex-shrink-0">{currentTip.emoji}</div>
              <p className="text-gray-600 text-sm sm:text-base pt-1">
                {currentTip.content}
              </p>
            </div>
            <div className="flex justify-between">
              {currentStep > 1 && (
                <button
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="p-1.5 rounded-full hover:bg-gray-100 text-gray-600 transition-colors"
                  aria-label="Previous tip"
                >
                  <ChevronLeft size={20} />
                </button>
              )}
              <button
                onClick={handleNext}
                className="p-1.5 rounded-full hover:bg-gray-100 text-gray-600 transition-colors ml-auto"
                aria-label="Next tip"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
} 