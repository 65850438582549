import React, { useEffect } from 'react';
import { useApp } from '../contexts/AppContext';
import QueuedGamesDisplay from '../components/QueuedGamesDisplay';
import LoadingSpinner from '../components/LoadingSpinner';

function QueuePage() {
  const { queue, following, games, spoilerMode } = useApp();
  
  useEffect(() => {
    console.log('QueuePage render:', {
      gamesAvailable: Array.isArray(games),
      gamesCount: games?.length,
      queueLength: queue?.length,
      queueIds: queue
    });
  }, [games, queue]);

  const queuedGames = Array.isArray(games) 
    ? games.filter(game => queue.includes(game.id))
    : [];

  console.log('Queued games:', queuedGames);

  return (
    <div className="container mx-auto">
      {!Array.isArray(games) ? (
        <div className="text-center py-8">
          <LoadingSpinner />
        </div>
      ) : queuedGames.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500 mt-4">No games in your queue yet.</p>
          <p className="text-sm text-gray-400 mt-2">
            Add games to your queue by clicking the bookmark icon on any game.
          </p>
        </div>
      ) : (
        <QueuedGamesDisplay 
          games={queuedGames}
          following={following}
          spoilerMode={spoilerMode}
        />
      )}
    </div>
  );
}

export default QueuePage; 