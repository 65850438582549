import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { auth, db } from '../services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { sportsApi } from '../services/sportsApi';
import { LEAGUES } from '../config/leagues';
import LoadingSpinner from '../components/LoadingSpinner';
import { youtubeAuthService } from '../services/youtubeAuthService';

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [following, setFollowing] = useState([]);
  const [spoilerMode, setSpoilerMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [queue, setQueue] = useState([]);
  const [isGuest, setIsGuest] = useState(false);
  const [isNewGuest, setIsNewGuest] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState(null);
  const [sessionFilters, setSessionFilters] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [channelSettings, setChannelSettings] = useState(() => {
    // Initialize with defaults from LEAGUES
    return Object.values(LEAGUES).reduce((acc, league) => {
      acc[league.name] = {
        channels: league.channels || [],
        searchParam: league.searchParam || ''
      };
      return acc;
    }, {});
  });
  const navigate = useNavigate();
  const [youtubeAuthorized, setYoutubeAuthorized] = useState(false);
  const [youtubeProfile, setYoutubeProfile] = useState(null);

  // Initialize YouTube auth state on mount
  useEffect(() => {
    const checkYouTubeAuth = () => {
      const isAuthorized = youtubeAuthService.isAuthorized();
      setYoutubeAuthorized(isAuthorized);
      if (isAuthorized) {
        setYoutubeProfile(youtubeAuthService.getProfile());
      }
    };

    checkYouTubeAuth();
  }, []);

  const handleYouTubeAuth = async () => {
    try {
      if (youtubeAuthorized) {
        await youtubeAuthService.signOut(currentUser?.uid);
        setYoutubeAuthorized(false);
        setYoutubeProfile(null);
      } else {
        const { token, profile } = await youtubeAuthService.signIn(currentUser?.uid);
        setYoutubeAuthorized(true);
        setYoutubeProfile(profile);
      }
    } catch (error) {
      console.error('YouTube auth error:', error);
      alert('Failed to connect to YouTube. Please try again.');
    }
  };

  const fetchTeamData = async (teamId) => {
    const [sport, espnId] = teamId.split('-');
    
    // Find the league for this sport
    const league = Object.values(LEAGUES).find(l => l.sport === sport);
    if (!league) {
      throw new Error(`Unsupported sport: ${sport}`);
    }
    
    const endpoint = `https://site.api.espn.com/apis/site/v2/sports/${sport}/${league.espnCode}/teams/${espnId}`;
    
    const response = await fetch(endpoint);
    const data = await response.json();
    
    return {
      id: teamId,
      espnId: data.team.id,
      name: data.team.displayName,
      abbreviation: data.team.abbreviation,
      logo: data.team.logos?.[0]?.href,
      league: league.name,
      sport: sport.toUpperCase(),
      color: data.team.color || '000000',
      alternateColor: data.team.alternateColor,
      record: `${data.team.record?.items?.[0]?.summary || 'N/A'}`
    };
  };

  const loadTeam = async (teamId) => {
    try {
      const teamData = await fetchTeamData(teamId);
      setTeams(current => {
        if (!current.find(t => t.id === teamId)) {
          return [...current, teamData];
        }
        return current;
      });
    } catch (error) {
      console.error('Error loading team:', error);
    }
  };

  const normalizeTeamIds = (following) => {
    return following.map(async (teamId) => {
      const [sport, id] = teamId.split('-');
      if (sport === 'soccer' && !teamId.includes('.')) {
        // This is an old format ID, try to get the correct league
        try {
          const response = await fetch(`${API_BASE_URL}/soccer/teams/${id}`);
          if (response.ok) {
            const data = await response.json();
            const league = data.sports?.[0]?.leagues?.[0]?.slug;
            if (league) {
              return `soccer-${league}-${id}`;
            }
          }
        } catch (error) {
          console.warn('Error normalizing team ID:', error);
        }
      }
      return teamId;
    });
  };

  // Combine filter initialization into one place
  const initializeFilters = useCallback(async (userData = null) => {
    const defaultFilterValues = {
      date: ['recent'],
      leagues: ['my-teams']
    };

    try {
      let filters;
      
      if (userData?.defaultFilters) {
        // Use user's saved filters
        filters = userData.defaultFilters;
      } else if (isGuest && isNewGuest) {
        // Use default filters for new guests
        filters = defaultFilterValues;
      } else {
        // Use empty filters as fallback
        filters = { date: [], leagues: [] };
      }

      // Ensure filters have required properties
      filters = {
        date: Array.isArray(filters.date) ? filters.date : defaultFilterValues.date,
        leagues: Array.isArray(filters.leagues) ? filters.leagues : defaultFilterValues.leagues
      };

      setDefaultFilters(filters);
      setActiveFilters(filters);
      setSessionFilters(filters);
      
    } catch (error) {
      console.error('Error initializing filters:', error);
      // Set safe defaults
      setDefaultFilters(defaultFilterValues);
      setActiveFilters(defaultFilterValues);
      setSessionFilters(defaultFilterValues);
    }
  }, [isGuest, isNewGuest]);

  // Update auth listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const previousUser = currentUser;  // Store previous auth state
      setCurrentUser(user);
      
      try {
        if (user) {
          setIsGuest(false);
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFollowing(userData.following || []);
            setSpoilerMode(userData.spoilerMode ?? true);
            setQueue(userData.queue || []);
            setIsFirstTimeUser(!userData.hasCompletedGuide);
            setChannelSettings(userData.channelSettings || LEAGUES);
            await initializeFilters(userData);
            
            // Load YouTube state
            if (userData.youtubeAuthorized) {
              setYoutubeAuthorized(true);
              setYoutubeProfile(userData.youtubeProfile);
            }
          } else {
            setIsFirstTimeUser(true);
            await initializeFilters();
          }
          
          // Only navigate to home if this is a fresh login (not a refresh)
          if (!previousUser && user) {
            navigate('/');
          }
        } else {
          setIsGuest(true);
          setFollowing([]);
          setSpoilerMode(false);
          setQueue([]);
          setChannelSettings(LEAGUES);
          await initializeFilters();
          
          // Only navigate to login if not already on auth pages
          if (!isGuest && 
              window.location.pathname !== '/login' && 
              window.location.pathname !== '/register' &&
              window.location.pathname !== '/') {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setChannelSettings(LEAGUES);
        await initializeFilters();
      } finally {
        setIsInitialized(true);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [navigate, isGuest, initializeFilters, currentUser]);

  // Load games for followed teams
  useEffect(() => {
    const loadGames = async () => {
      try {
        const daysRange = { past: 7, future: 7 };
        const allGames = await sportsApi.getAllGames(daysRange);
        
        // Filter games based on followed teams OR queued games
        const filteredGames = allGames.filter(game => {
          const isFollowedTeam = following.includes(game.homeTeam.id) || 
                                following.includes(game.awayTeam.id);
          const isQueued = queue.includes(game.id);
          const league = Object.values(LEAGUES).find(l => l.name === game.league);
          return (isFollowedTeam || isQueued) && league;
        });
        setGames(filteredGames);
      } catch (error) {
        console.error('Error loading games:', error);
      }
    };

    loadGames();
  }, [following, queue]);

  const updateFollowState = async ({ teamIds, state, userId }) => {
    if (!userId) return;
    
    const userDocRef = doc(db, 'users', userId);
    
    try {
      // Use arrayUnion/arrayRemove to handle the array updates atomically
      await updateDoc(userDocRef, {
        following: state ? arrayUnion(...teamIds) : arrayRemove(...teamIds)
      });
      
      return true;
    } catch (error) {
      console.error('Firebase update error:', error);
      throw error;
    }
  };

  const toggleFollow = async (teamIds, targetState) => {
    if (!Array.isArray(teamIds)) {
      teamIds = [teamIds];
    }

    try {
      // Single backend call to update all teams
      await updateFollowState({
        teamIds,
        state: targetState,
        userId: currentUser?.uid // Make sure to use the correct user ID property
      });
      
      // Single state update
      setFollowing(prev => {
        if (targetState) {
          return [...new Set([...prev, ...teamIds])];
        } else {
          return prev.filter(id => !teamIds.includes(id));
        }
      });
    } catch (error) {
      console.error('Error updating follow state:', error);
    }
  };

  const toggleSpoilerMode = useCallback(async () => {
    const newMode = !spoilerMode;
    setSpoilerMode(newMode);
    localStorage.setItem('spoilerMode', JSON.stringify(newMode));

    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          spoilerMode: newMode
        });
      } catch (error) {
        console.error('Error updating spoiler mode:', error);
        // Revert on error
        setSpoilerMode(!newMode);
        localStorage.setItem('spoilerMode', JSON.stringify(!newMode));
      }
    }
  }, [spoilerMode, currentUser]);

  const toggleQueue = async (gameId) => {
    if (!currentUser) return;

    const newQueue = queue.includes(gameId)
      ? queue.filter(id => id !== gameId)
      : [...queue, gameId];

    setQueue(newQueue);

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        queue: newQueue
      });
    } catch (error) {
      console.error('Error updating queue:', error);
      setQueue(queue); // Revert on error
    }
  };

  const addToQueue = useCallback(async (gameId) => {
    if (!currentUser) return;
    
    const newQueue = [...queue, gameId];
    setQueue(newQueue);
    
    // Persist to Firestore
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        queue: newQueue
      });
    } catch (error) {
      console.error('Error updating queue:', error);
      // Optionally revert the local state if the save fails
      setQueue(queue);
    }
  }, [queue, currentUser]);

  const removeFromQueue = useCallback(async (gameId) => {
    if (!currentUser) return;
    
    const newQueue = queue.filter(id => id !== gameId);
    setQueue(newQueue);
    
    // Persist to Firestore
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        queue: newQueue
      });
    } catch (error) {
      console.error('Error updating queue:', error);
      // Optionally revert the local state if the save fails
      setQueue(queue);
    }
  }, [queue, currentUser]);

  // Make sure to load the queue when the user logs in
  useEffect(() => {
    if (currentUser?.queue) {
      setQueue(currentUser.queue);
    } else {
      setQueue([]); // Reset queue when user logs out
    }
  }, [currentUser]);

  const followTeam = (teamId) => {
    setCurrentUser(currentUser => {
      if (!currentUser) return currentUser;
      
      const following = currentUser.following || [];
      if (!following.includes(teamId)) {
        const updatedFollowing = [...following, teamId];
        return {
          ...currentUser,
          following: updatedFollowing
        };
      }
      return currentUser;
    });
  };

  const unfollowTeam = (teamId) => {
    setCurrentUser(currentUser => {
      if (!currentUser) return currentUser;
      
      const following = currentUser.following || [];
      const updatedFollowing = following.filter(id => id !== teamId);
      return {
        ...currentUser,
        following: updatedFollowing
      };
    });
  };

  const setGuestMode = useCallback((value) => {
    console.log('Setting guest mode to:', value);
    setIsGuest(value);
    if (value) {
      // Initialize guest data
      setFollowing([]);
      setSpoilerMode(false);
      setQueue([]);
      // Set initial filters for first-time guests only
      if (isNewGuest) {
        setDefaultFilters({
          date: ['recent'],
          leagues: ['my-teams']
        });
      }
      // Only navigate if not already on home page
      if (window.location.pathname !== '/') {
        navigate('/', { replace: true });
      }
    }
  }, [navigate, isNewGuest]);

  // Update the updateDefaultFilters function to optionally skip updating session filters
  const updateDefaultFilters = useCallback(async (newFilters, { updateSession = true } = {}) => {
    const resolvedFilters = typeof newFilters === 'function' 
      ? newFilters(defaultFilters)
      : newFilters;

    const plainFilters = {
      date: Array.isArray(resolvedFilters.date) ? resolvedFilters.date.slice() : [],
      leagues: Array.isArray(resolvedFilters.leagues) ? resolvedFilters.leagues.slice() : []
    };

    // Update local state
    setDefaultFilters(plainFilters);
    
    // Only update session/active filters if specified
    if (updateSession) {
      setActiveFilters(plainFilters);
      setSessionFilters(plainFilters);
    }

    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          defaultFilters: plainFilters
        });
      } catch (error) {
        console.error('Error updating default filters:', error);
      }
    }
  }, [currentUser, defaultFilters]);

  // Add a new function to update session filters
  const updateSessionFilters = useCallback((newFilters) => {
    const resolvedFilters = typeof newFilters === 'function' 
      ? newFilters(sessionFilters || defaultFilters)
      : newFilters;

    setSessionFilters(resolvedFilters);
    setActiveFilters(resolvedFilters);
  }, [sessionFilters, defaultFilters]);

  useEffect(() => {
    const loadFollowing = async () => {
      const savedFollowing = localStorage.getItem('following');
      if (savedFollowing) {
        const following = JSON.parse(savedFollowing);
        const normalizedFollowing = await Promise.all(following.map(normalizeTeamIds));
        setFollowing(normalizedFollowing);
      }
    };
    loadFollowing();
  }, []);

  // Add function to mark guide as completed
  const markGuideComplete = async () => {
    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          hasCompletedGuide: true
        });
        setIsFirstTimeUser(false);
      } catch (error) {
        console.error('Error updating guide completion:', error);
      }
    }
  };

  // Initialize active filters from defaults when component mounts
  useEffect(() => {
    if (!activeFilters) {
      setActiveFilters(defaultFilters);
    }
  }, [defaultFilters]);

  // Load user preferences when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      // Load user's default filters from your backend/storage
      loadUserPreferences().then(prefs => {
        setDefaultFilters(prefs.filters);
      });
    }
  }, [isAuthenticated]);

  // Clear session filters on logout if you have that functionality
  const handleLogout = () => {
    setSessionFilters(null);
    // ... other logout logic
  };

  // Update channel settings in both state and Firestore
  const updateChannelSettings = async (newSettings) => {
    try {
      if (!currentUser) {
        console.log('❌ No user logged in, cannot save settings');
        throw new Error('Must be logged in to save channel settings');
      }

      console.log('💾 Saving channel settings:', newSettings);
      
      // Update local state
      setChannelSettings(newSettings);
      
      // Save to Firestore
      await updateDoc(doc(db, 'users', currentUser.uid), {
        channelSettings: newSettings
      });
      console.log('✅ Settings saved to Firestore');

      // Backup to localStorage
      localStorage.setItem('channelSettings', JSON.stringify(newSettings));
      console.log('📦 Settings backed up to localStorage');
    } catch (error) {
      console.error('❌ Failed to save channel settings:', error);
      throw error;
    }
  };

  // Load user's channel settings when they log in
  useEffect(() => {
    const loadUserSettings = async () => {
      try {
        if (currentUser) {
          console.log('🔄 Loading settings for user:', currentUser.uid);
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.channelSettings) {
              console.log('📥 Loaded settings from Firestore:', userData.channelSettings);
              setChannelSettings(userData.channelSettings);
            } else {
              console.log('ℹ️ No settings found in Firestore');
            }
          }
        } else {
          console.log('👤 No user logged in, checking localStorage');
          const savedSettings = localStorage.getItem('channelSettings');
          if (savedSettings) {
            console.log('📦 Loaded settings from localStorage');
            setChannelSettings(JSON.parse(savedSettings));
          }
        }
      } catch (error) {
        console.error('❌ Error loading channel settings:', error);
      }
    };

    loadUserSettings();
  }, [currentUser]);

  // Load settings on init
  useEffect(() => {
    const savedSettings = localStorage.getItem('channelSettings');
    if (savedSettings) {
      try {
        setChannelSettings(JSON.parse(savedSettings));
      } catch (error) {
        console.error('Failed to load saved channel settings:', error);
      }
    }
  }, []);

  const value = {
    currentUser,
    following,
    spoilerMode,
    loading,
    games,
    teams,
    queue,
    toggleFollow,
    toggleSpoilerMode,
    toggleQueue,
    addToQueue,
    removeFromQueue,
    loadTeam,
    followTeam,
    unfollowTeam,
    setSpoilerMode,
    isGuest,
    setGuestMode,
    isNewGuest,
    setIsNewGuest,
    defaultFilters,
    setDefaultFilters,  // Used by PreferencesPage
    activeFilters,
    setActiveFilters,  // Used by GamesDisplay
    isFirstTimeUser,
    markGuideComplete,
    isAuthenticated,
    sessionFilters,
    setSessionFilters,
    isInitialized,
    isLoading: loading || !isInitialized,
    updateDefaultFilters, // Add this to the context value
    updateSessionFilters,  // Add this new function
    channelSettings,
    updateChannelSettings,
    youtubeAuthorized,
    youtubeProfile,
    handleYouTubeAuth,
  };

  return (
    <AppContext.Provider value={value}>
      {loading || !isInitialized ? (
        null
      ) : (
        <Outlet />
      )}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}; 