// src/services/sportsApi.js

import { 
  LEAGUES, 
  getLeagueApiUrl, 
  processGameStatus, 
  formatTeamData,
  API_BASE_URL 
} from '../config/leagues';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: 'America/New_York'
  });
};

const getDateRange = (past, future) => {
  const dates = [];
  const today = new Date();
  
  // Add past dates
  for (let i = past; i > 0; i--) {
    const date = new Date(today);
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split('T')[0].replace(/-/g, ''));
  }
  
  // Add today
  dates.push(today.toISOString().split('T')[0].replace(/-/g, ''));
  
  // Add future dates
  for (let i = 1; i <= future; i++) {
    const date = new Date(today);
    date.setDate(date.getDate() + i);
    dates.push(date.toISOString().split('T')[0].replace(/-/g, ''));
  }
  
  return dates;
};

const processTeams = (competitors, league) => {
  const homeTeam = competitors.find(c => c.homeAway === 'home');
  const awayTeam = competitors.find(c => c.homeAway === 'away');
  
  return {
    homeTeam: {
      ...formatTeamData(homeTeam, league),
      record: homeTeam.records?.[0]?.summary || homeTeam.record?.summary || null,
      rank: homeTeam.statistics?.find(s => s.name === 'rank')?.value || null
    },
    awayTeam: {
      ...formatTeamData(awayTeam, league),
      record: awayTeam.records?.[0]?.summary || awayTeam.record?.summary || null,
      rank: awayTeam.statistics?.find(s => s.name === 'rank')?.value || null
    }
  };
};

export const sportsApi = {
  async getAllTeams() {
    try {
      const allTeams = await Promise.all(
        Object.values(LEAGUES).map(league => this.getLeagueTeams(league.id))
      );
      return allTeams.flat();
    } catch (error) {
      console.error('Error fetching all teams:', error);
      return [];
    }
  },

  async getLeagueTeams(leagueId) {
    try {
      const url = getLeagueApiUrl(leagueId, '/teams');
      if (!url) throw new Error('Invalid league ID');

      const response = await fetch(url);
      const data = await response.json();

      return data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
        id: `${team.sport}-${team.team.id}`,
        name: team.team.displayName,
        abbreviation: team.team.abbreviation,
        logo: team.team.logos?.[0]?.href,
        league: team.league.name,
        sport: team.sport,
        color: team.team.color || '000000',
        alternateColor: team.team.alternateColor
      })) || [];
    } catch (error) {
      console.error(`Error fetching teams for league ${leagueId}:`, error);
      return [];
    }
  },

  async getAllGames(daysRange = { past: 7, future: 7 }) {
    try {
      const leagues = Object.values(LEAGUES);
      const gamesPromises = leagues.map(league => this.getLeagueGames(league.id, daysRange));
      const gamesArrays = await Promise.all(gamesPromises);
      
      // Flatten arrays and ensure rawDate is properly formatted
      const allGames = gamesArrays.flat().map(game => ({
        ...game,
        rawDate: new Date(game.rawDate).toISOString() // Ensure consistent date format
      }));

      return allGames;
    } catch (error) {
      console.error('Error fetching all games:', error);
      throw error;
    }
  },

  async getLeagueGames(leagueId, daysRange = { past: 7, future: 7 }) {
    try {
      const dates = getDateRange(daysRange.past, daysRange.future);
      const league = Object.values(LEAGUES).find(l => l.id === leagueId);
      if (!league) throw new Error('Invalid league ID');

      const gamesPromises = dates.map(async date => {
        const url = getLeagueApiUrl(leagueId, `/scoreboard?dates=${date}&lang=en`);
        if (!url) throw new Error('Invalid league ID');

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.events || [];
      });
  
      const allEvents = (await Promise.all(gamesPromises)).flat();
  
      return allEvents.map(event => {
        try {
          const competitors = event.competitions[0].competitors;
          const { homeTeam, awayTeam } = processTeams(competitors, league);
          const gameDate = new Date(event.date);
          
          return {
            id: event.id,
            league: league.name,
            sport: league.sport,
            date: formatDate(gameDate),
            rawDate: event.date, // Directly use the ISO format from the API
            time: formatTime(gameDate),
            status: processGameStatus(event.status),
            statusDetail: event.status.type.shortDetail,
            homeTeam: {
              ...homeTeam,
              logo: homeTeam.logo || null, // Ensure we always have a value for logo
            },
            awayTeam: {
              ...awayTeam,
              logo: awayTeam.logo || null, // Ensure we always have a value for logo
            },
            venue: event.competitions[0].venue?.fullName || 'TBD'
          };
        } catch (error) {
          console.error(`Error processing event ${event.id}:`, error);
          return null;
        }
      }).filter(Boolean); // Remove any null entries
  
    } catch (error) {
      console.error(`Error fetching games for league ${leagueId}:`, error);
      return [];
    }
  },

  async getGameById(sport, league, gameId) {
    try {
      const response = await fetch(
        `https://site.api.espn.com/apis/site/v2/sports/${sport}/${league}/scoreboard/events/${gameId}`,
        {
          headers: {
            'Accept': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching game:', error);
      throw error;
    }
  },

  async getTeamGames(teamId, sport, daysRange = { past: 7, future: 7 }, specificLeague = null) {
    try {
      const dates = getDateRange(daysRange.past, daysRange.future);
      
      // Parse team ID based on different formats
      let teamNumber, leagueId;
      if (teamId.startsWith('wnba-')) {
        // Handle WNBA format: 'wnba-{teamId}'
        [_, teamNumber] = teamId.split('wnba-');
        sport = 'basketball';
        leagueId = 'wnba';
      } else if (teamId.startsWith('soccer-')) {
        // Handle soccer format: 'soccer-{leagueId}-{teamId}'
        const parts = teamId.split('-');
        teamNumber = parts[parts.length - 1];
        leagueId = parts[1];
        sport = 'soccer';
      } else {
        // Handle standard format: '{sport}-{leagueId}-{teamId}' or '{sport}-{teamId}'
        const parts = teamId.split('-');
        teamNumber = parts[parts.length - 1];
        leagueId = parts.length > 2 ? parts[1] : null;
      }
      
      // Get the appropriate league
      let league;
      if (specificLeague) {
        league = Object.values(LEAGUES).find(l => l.id === specificLeague);
      } else if (leagueId) {
        league = Object.values(LEAGUES).find(l => l.id === leagueId);
      } else {
        league = Object.values(LEAGUES).find(l => l.sport === sport);
      }
      
      if (!league) {
        throw new Error('Invalid sport/league');
      }

      const gamesPromises = dates.map(async date => {
        // Array of possible URL patterns to try
        const urlPatterns = [
          // Pattern 1: Basic scoreboard with team parameter
          `${API_BASE_URL}/${sport}/${league.id}/scoreboard?dates=${date}&lang=en&team=${teamNumber}`,
          // Pattern 2: Basic scoreboard without team parameter
          `${API_BASE_URL}/${sport}/${league.id}/scoreboard?dates=${date}&lang=en`,
          // Pattern 3: Team-specific schedule endpoint
          `${API_BASE_URL}/${sport}/${league.id}/teams/${teamNumber}/schedule?dates=${date}&lang=en`,
          // Pattern 4: League-specific schedule endpoint
          `${API_BASE_URL}/${sport}/${league.id}/schedule?dates=${date}&lang=en`
        ];

        // For WNBA, add specific patterns
        if (sport === 'basketball' && leagueId === 'wnba') {
          urlPatterns.unshift(
            `${API_BASE_URL}/basketball/wnba/scoreboard?dates=${date}&lang=en&team=${teamNumber}`,
            `${API_BASE_URL}/basketball/wnba/teams/${teamNumber}/schedule?dates=${date}&lang=en`
          );
        }

        let events = [];
        let successfulFetch = false;

        // Try each URL pattern until one works
        for (const url of urlPatterns) {
          try {
            const response = await fetch(url);
            if (response.ok) {
              const data = await response.json();
              
              // Handle different response structures
              if (data.events) {
                events = data.events;
              } else if (data.dates?.[0]?.events) {
                events = data.dates[0].events;
              } else if (data.games) {
                events = data.games;
              }
              
              successfulFetch = true;
              break;
            }
          } catch (error) {
            continue; // Try next pattern if this one fails
          }
        }

        if (!successfulFetch) return [];

        // Filter for the specific team
        return events.filter(event => {
          const competitors = event.competitions?.[0]?.competitors || 
                            event.competitors ||
                            [];
          return competitors.some(comp => {
            const compTeamId = comp.team?.id || comp.id;
            return compTeamId === teamNumber;
          });
        });
      });

      const gamesArrays = await Promise.all(gamesPromises);
      const allEvents = gamesArrays.flat();

      // Process and return the filtered games
      return allEvents.map(event => {
        try {
          const competitors = event.competitions?.[0]?.competitors || 
                            event.competitors ||
                            [];
          const { homeTeam, awayTeam } = processTeams(competitors, league);
          const gameDate = new Date(event.date);
          
          return {
            id: event.id,
            league: league.name,
            sport: league.sport,
            date: formatDate(gameDate),
            rawDate: event.date,
            time: formatTime(gameDate),
            status: processGameStatus(event.status),
            statusDetail: event.status?.type?.shortDetail || 'Unknown',
            homeTeam: {
              ...homeTeam,
              logo: homeTeam.logo || null,
            },
            awayTeam: {
              ...awayTeam,
              logo: awayTeam.logo || null,
            },
            venue: event.competitions?.[0]?.venue?.fullName || 
                   event.venue?.fullName || 
                   'TBD'
          };
        } catch (error) {
          return null;
        }
      }).filter(Boolean);

    } catch (error) {
      return [];
    }
  },

  async getTeamDetails(teamId) {
    try {
      const [sport, id] = teamId.split('-');
      const league = Object.values(LEAGUES).find(l => l.sport === sport);
      if (!league) throw new Error('Invalid sport');

      const url = `${API_BASE_URL}/${sport}/${league.id}/teams/${id}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      const team = data.team;

      return {
        id: teamId,
        name: team.displayName,
        abbreviation: team.abbreviation,
        nickname: team.nickname,
        logo: team.logos?.[0]?.href,
        league: league.name,
        sport: league.sport,
        color: team.color || '000000',
        alternateColor: team.alternateColor,
        record: team.record,
        standingSummary: team.standingSummary
      };
    } catch (error) {
      console.error(`Error fetching team details for ${teamId}:`, error);
      throw error;
    }
  },

  async getTeamRoster(teamId) {
    try {
      const [sport, id] = teamId.split('-');
      const league = Object.values(LEAGUES).find(l => l.sport === sport);
      if (!league) throw new Error('Invalid sport');

      const url = `${API_BASE_URL}/${sport}/${league.id}/teams/${id}/athletes`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.athletes?.map(athlete => ({
        id: athlete.id,
        name: athlete.displayName,
        firstName: athlete.firstName,
        lastName: athlete.lastName,
        position: athlete.position?.name,
        positionAbbr: athlete.position?.abbreviation,
        number: athlete.jersey,
        height: athlete.height,
        weight: athlete.weight,
        age: athlete.age,
        birthPlace: athlete.birthPlace?.city + ', ' + athlete.birthPlace?.country,
        headshot: athlete.headshot?.href
      })) || [];
    } catch (error) {
      console.error(`Error fetching team roster for ${teamId}:`, error);
      return [];
    }
  },

  async getTeamStandings(teamId) {
    try {
      const teamData = await this.getTeamDetails(teamId);
      if (!teamData.record?.items) return null;

      // Get overall record
      const overall = teamData.record.items.find(item => item.type === 'total');
      if (!overall) return null;

      const stats = overall.stats;
      const findStat = (name) => stats.find(s => s.name === name)?.value;

      return {
        summary: overall.summary, // "11-10-3"
        standingSummary: teamData.standingSummary, // "4th in Metropolitan Division"
        stats: {
          wins: findStat('wins') || 0,
          losses: findStat('losses') || 0,
          otLosses: findStat('otLosses') || 0,
          points: findStat('points') || 0,
          gamesPlayed: findStat('gamesPlayed') || 0,
          powerPlayPct: findStat('powerPlayPct') || 0,
          penaltyKillPct: findStat('penaltyKillPct') || 0,
          shootoutWins: findStat('shootoutWins') || 0,
          shootoutLosses: findStat('shootoutLosses') || 0
        },
        records: {
          home: teamData.record.items.find(item => item.type === 'home')?.summary,
          away: teamData.record.items.find(item => item.type === 'road')?.summary
        }
      };
    } catch (error) {
      console.error(`Error fetching team standings for ${teamId}:`, error);
      return null;
    }
  },

  // Enhanced version of existing getTeamGames to include more details
  async getTeamSchedule(teamId) {
    try {
      const [sport, id] = teamId.split('-');
      const league = Object.values(LEAGUES).find(l => l.sport === sport);
      if (!league) throw new Error('Invalid sport');

      const url = `${API_BASE_URL}/${sport}/${league.id}/teams/${id}/schedule`;

      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const data = await response.json();
      
      const events = data.events?.map(event => {
        const competition = event.competitions?.[0] || {};
        const competitors = competition.competitors || [];
        
        return {
          id: event.id,
          date: event.date,
          competitors: competitors.map(comp => ({
            id: comp.team?.id,
            abbreviation: comp.team?.abbreviation,
            homeAway: comp.homeAway,
            score: comp.score,
            winner: comp.winner
          }))
        };
      }) || [];

      return events;
    } catch (error) {
      console.error(`Error fetching team schedule for ${teamId}:`, error);
      return [];
    }
  },

  async getLeagueStandings(teamId) {
    try {
      const [sport, id] = teamId.split('-');
      const league = Object.values(LEAGUES).find(l => l.sport === sport);
      if (!league) throw new Error('Invalid sport');

      const url = `${API_BASE_URL}/${sport}/${league.id}/standings`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Find the team's division/conference
      let teamStandings = null;
      let divisionStandings = [];

      if (data.children) { // For NHL/NBA style
        for (const conference of data.children) {
          for (const division of conference.children || []) {
            const standings = division.standings?.entries || [];
            const teamEntry = standings.find(entry => entry.team.id === id);
            if (teamEntry) {
              teamStandings = teamEntry;
              divisionStandings = standings;
              break;
            }
          }
          if (teamStandings) break;
        }
      }

      return {
        team: teamStandings,
        division: divisionStandings.map(entry => ({
          teamId: `${sport}-${entry.team.id}`,
          name: entry.team.name,
          wins: entry.stats.find(s => s.name === 'wins')?.value,
          losses: entry.stats.find(s => s.name === 'losses')?.value,
          otLosses: entry.stats.find(s => s.name === 'otLosses')?.value,
          points: entry.stats.find(s => s.name === 'points')?.value,
          gamesPlayed: entry.stats.find(s => s.name === 'gamesPlayed')?.value,
          streak: entry.stats.find(s => s.name === 'streak')?.value
        }))
      };
    } catch (error) {
      console.error(`Error fetching league standings for ${teamId}:`, error);
      return null;
    }
  }
};