import React, { useState } from 'react';
import QueueButton from './QueueButton';
import { useNavigate } from 'react-router-dom';
import { getShortTeamName } from '../utils/teamUtils';
import { youtubeApi } from '../services/youtubeApi';
import { Play, X } from 'lucide-react';
import { useApp } from '../contexts/AppContext';

const formatRelativeDate = (rawDate) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const gameDate = new Date(rawDate);
  const gameDateMidnight = new Date(
    gameDate.getFullYear(),
    gameDate.getMonth(),
    gameDate.getDate()
  );

  if (gameDateMidnight.getTime() === today.getTime()) return 'Today';
  if (gameDateMidnight.getTime() === tomorrow.getTime()) return 'Tomorrow';
  if (gameDateMidnight.getTime() === yesterday.getTime()) return 'Yesterday';

  return gameDate.toLocaleDateString('en-US', { 
    weekday: 'short', 
    month: 'short', 
    day: 'numeric' 
  });
};

const HighlightsButton = ({ onClick, loading, gameId, game, videoUrl }) => {
  const isRecentlyEnded = () => {
    if (game.status !== 'Final') return false;
    
    const gameEndTime = new Date(game.rawDate);
    const now = new Date();
    const hoursSinceEnd = (now - gameEndTime) / (1000 * 60 * 60);
    
    return hoursSinceEnd < 3;
  };

  if (isRecentlyEnded()) {
    return (
      <button
        disabled
        className="mt-2 px-2 py-1 rounded-md text-gray-500 bg-gray-100 mx-auto flex items-center gap-1 text-xs cursor-not-allowed"
        title="Highlights will be available soon"
      >
        <Play size={14} className="text-gray-400" />
        <span>Coming Soon</span>
      </button>
    );
  }

  const buttonClasses = "mt-2 px-2 py-1 rounded-md text-blue-600 hover:bg-blue-100 flex items-center gap-1 text-xs";

  const ButtonContent = () => (
    <>
      <Play size={14} />
      <span>Highlights</span>
    </>
  );

  return (
    <button
      onClick={onClick}
      disabled={loading}
      data-highlights-button={gameId}
      className={`${buttonClasses} ${loading ? 'bg-gray-100' : 'bg-blue-50'}`}
      title="Watch Highlights"
    >
      {loading ? (
        <span className="animate-pulse">Loading...</span>
      ) : (
        <ButtonContent />
      )}
    </button>
  );
};

const VideoModal = ({ videoId, onClose }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
    <div className="relative w-full max-w-4xl mx-4">
      <button 
        onClick={onClose}
        className="absolute -top-10 right-0 text-white hover:text-gray-300"
      >
        <X size={24} />
      </button>
      <div className="relative pt-[56.25%] bg-black rounded-lg overflow-hidden">
        <iframe
          className="absolute inset-0 w-full h-full"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="Game Highlights"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  </div>
);

function QueuedGamesDisplay({ games, following, spoilerMode }) {
  const navigate = useNavigate();
  const { channelSettings, youtubeAuthorized } = useApp();
  const [loadingHighlights, setLoadingHighlights] = useState({});
  const [highlightVideoId, setHighlightVideoId] = useState(null);
  const [activeGameId, setActiveGameId] = useState(null);
  const [videoUrls, setVideoUrls] = useState({});

  const gamesByDate = games.reduce((acc, game) => {
    const gameDate = new Date(game.rawDate || game.date);
    const localDate = new Date(
      gameDate.getFullYear(),
      gameDate.getMonth(),
      gameDate.getDate()
    ).toISOString();

    if (!acc[localDate]) {
      acc[localDate] = [];
    }
    acc[localDate].push(game);
    return acc;
  }, {});

  const handleTeamClick = (teamId) => {
    navigate(`/team/${teamId}`);
  };

  const handleHighlightClick = async (gameId, game) => {
    try {
      if (!youtubeAuthorized && videoUrls[gameId]) {
        window.location.href = videoUrls[gameId];
        return;
      }

      setLoadingHighlights(prev => ({ ...prev, [gameId]: true }));
      
      const result = await youtubeApi.getHighlightVideo(
        game.homeTeam.name,
        game.awayTeam.name,
        game.rawDate,
        game.league,
        channelSettings
      );

      if (!result || (!result.videoId && !result.searchQuery)) {
        throw new Error('No video found');
      }

      if (youtubeAuthorized) {
        setHighlightVideoId(result.videoId);
        setActiveGameId(gameId);
        return;
      }

      let url;
      if (result.type === 'direct' && result.videoId) {
        url = `https://www.youtube.com/watch?v=${result.videoId}`;
      } else if (result.searchQuery) {
        url = `https://www.youtube.com/results?search_query=${encodeURIComponent(result.searchQuery)}`;
      }

      if (url) {
        setVideoUrls(prev => ({ ...prev, [gameId]: url }));
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error fetching highlights:', error);
      alert('Unable to load highlights');
    } finally {
      setLoadingHighlights(prev => ({ ...prev, [gameId]: false }));
    }
  };

  return (
    <div className="px-0">
      {Object.entries(gamesByDate)
        .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
        .map(([date, dateGames], dateIndex) => (
          <div key={date} className={`bg-white overflow-hidden ${dateIndex === 0 ? 'mt-2' : ''}`}>
            <div className="px-1 py-3 bg-gray-50 border-b">
              <h2 className="text-sm font-medium text-gray-600 px-2">
                {formatRelativeDate(date)}
              </h2>
            </div>

            <div className="divide-y">
              {dateGames.map(game => (
                <div 
                  key={game.id} 
                  className="px-2 pt-4 pb-8 sm:px-6 sm:pt-5 sm:pb-8 transition-colors relative"
                >
                  <div className="flex items-center justify-between text-xs sm:text-sm text-gray-500 mb-6">
                    <span>{game.league}</span>
                    <div className="flex items-center gap-4">
                      <span>{game.time}</span>
                      <QueueButton 
                        gameId={game.id} 
                        data-bookmark-button={game.id}
                        className="z-[40]"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 items-center gap-4 sm:grid-cols-[2fr_1fr_2fr]">
                    {/* Home Team */}
                    <div className="flex flex-col items-center gap-1 text-center">
                      {game.homeTeam.logo && (
                        <img 
                          src={game.homeTeam.logo} 
                          alt=""
                          className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                          onClick={() => handleTeamClick(game.homeTeam.id)}
                        />
                      )}
                      <div className="flex flex-col items-center">
                        <span className={`text-xs font-medium ${following.includes(game.homeTeam.id) ? 'text-blue-600' : ''}`}>
                          {getShortTeamName(game.homeTeam.name, game.league)}
                        </span>
                      </div>
                    </div>

                    {/* Score/Status */}
                    <div className="flex flex-col items-center justify-center w-full">
                      {game.status === 'In Progress' ? (
                        <div className="text-center">
                          <div className="font-bold text-sm">
                            {spoilerMode ? (
                              <span className="text-green-600">LIVE</span>
                            ) : (
                              `${game.homeTeam.score} - ${game.awayTeam.score}`
                            )}
                          </div>
                          <div className="text-xs text-green-600">
                            {spoilerMode ? 'In Progress' : game.statusDetail}
                          </div>
                        </div>
                      ) : game.status === 'Final' ? (
                        <div className="text-center flex flex-col items-center">
                          <div className="font-bold text-sm">
                            {spoilerMode ? (
                              'FINAL'
                            ) : (
                              `${game.homeTeam.score} - ${game.awayTeam.score}`
                            )}
                          </div>
                          {(game.hasHighlights || youtubeApi.isHighlightAvailable(game)) && (
                            <HighlightsButton 
                              onClick={() => handleHighlightClick(game.id, game)}
                              loading={loadingHighlights[game.id]}
                              gameId={game.id}
                              game={game}
                              videoUrl={!youtubeAuthorized ? videoUrls[game.id] : null}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="text-gray-500 text-sm">vs</div>
                      )}
                    </div>

                    {/* Away Team */}
                    <div className="flex flex-col items-center gap-1 text-center">
                      {game.awayTeam.logo && (
                        <img 
                          src={game.awayTeam.logo} 
                          alt=""
                          className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                          onClick={() => handleTeamClick(game.awayTeam.id)}
                        />
                      )}
                      <div className="flex flex-col items-center">
                        <span className={`text-xs font-medium ${following.includes(game.awayTeam.id) ? 'text-blue-600' : ''}`}>
                          {getShortTeamName(game.awayTeam.name, game.league)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

      {highlightVideoId && (
        <VideoModal 
          videoId={highlightVideoId} 
          onClose={() => {
            setHighlightVideoId(null);
            setActiveGameId(null);
          }} 
        />
      )}
    </div>
  );
}

export default QueuedGamesDisplay; 